import { ProgressIndicator } from "@dnb/eufemia";
import React from "react";

export const Timer = ({ handleTimeout }: { handleTimeout: () => void }) => {
  const [progress, setProgressIndicator] = React.useState(100);
  React.useEffect(() => {
    const timer = setTimeout(() => {
      if (progress > 0) {
        setProgressIndicator(progress - 1);
      }
    }, 100);

    return () => {
      handleTimeout();
      clearInterval(timer);
    };
  });
  return <ProgressIndicator size="large" type="linear" progress={progress} />;
};
