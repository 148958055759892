import { Collectable } from "../../models/ApiModels";
import { useInterval } from "usehooks-ts";
import { useEffect, useState } from "react";
import { Frame } from "../../pages/Gallery";

interface NftProgressListProps {
  title?: string;
  fractalApiEndpoint: string;
  accessToken: string;
  getRecentlySoldCollectibles: () => Promise<Collectable[]>;
}

export function LatestNftList(props: NftProgressListProps) {
  const { accessToken } = props;
  const [soldCollectables, setSoldCollectables] = useState<Array<Collectable>>(
    []
  );

  function cleanAndFilterUsers(collectables: Collectable[]) {
    const collectablesCleaned = collectables.map((item) => {
      const updateCollectible: Collectable = {
        ...item,
      };
      return updateCollectible;
    });
    return collectablesCleaned;
  }

  const getRecentlySoldCollectables = async () => {
    const collectables = await props.getRecentlySoldCollectibles();
    setSoldCollectables(cleanAndFilterUsers(collectables));
  };

  useInterval(
    () => {
      if (accessToken) {
        getRecentlySoldCollectables();
      }
    },
    // Delay in milliseconds or null to stop it
    5000
  );

  useEffect(() => {
    if (accessToken) {
      getRecentlySoldCollectables();
    }
  }, [accessToken]);

  if (soldCollectables.length === 0) {
    return (
      <div>
        <h2 className="font-hore text-2xl text-white border-b border-sea-green p-2">
          {props.title}
        </h2>
        <div
          key={"foo"}
          className="grid text-white grid-cols-2 gap-2 border-b border-sea-green"
        >
          <div>
            <p className="text-left pr-4 pl-4 pb-1">no sales yet</p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <h2 className="font-hore text-2xl text-white p-1 pb-2">{props.title}</h2>
      <div className="border-sea-green border rounded-md bg-black bg-opacity-40 mb-4 pb-5">
        <div className="grid sm:grid-cols-1  md:grid-cols-2  lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 gap-2 flex">
          {soldCollectables.slice(0, 8).map((soldCollectables) => (
            <div key={soldCollectables.id} className="content-center m-auto">
              <div className="rounded bg-opacity-90">
                <div className="rounded-md">
                  <Frame
                    collectable={soldCollectables}
                    fractalApiEndpoint={props.fractalApiEndpoint}
                  />
                </div>
                <p className="text-white font-dnb_light">
                  {soldCollectables.owner_id}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
