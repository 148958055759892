import { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";
import { WalletId } from "./components/InventoryContainer";
import { TransferRequest } from "./models/ApiModels";

import { RedeemPage } from "./pages/RedeemPage";
import { apiEndpoint, fractalEndpoint, inventoryService } from ".";

import { InventOrEe } from "./pages/InventOrEe";
import { Home } from "./pages/Home";
import { BankVaultDashBoard } from "./pages/BankVaultDashboard";
import { TokenAndNftCollection } from "./pages/TokenAndNftCollection";
import { useAuth0 } from "@auth0/auth0-react";

import { LoginPage } from "./components/LoginPage";
import { MobileHome } from "./pages/MobileHome";
import { Gallery } from "./pages/Gallery";
import {
  getCollectibles,
  getRecentlySoldCollectibles,
} from "./service/CollectableService";
import {
  Contract,
  ContractAndBalance,
  ContractAndSupply,
  Token,
  Wallet,
} from "./models/ApplicationModels";
import "@dnb/eufemia/style";
import { TicTacToken } from "./pages/TicTacToken";
import { NavBar } from "./components/NavBar";
import { getWallets } from "./service/UserService";
import { BuyCrypto } from "./pages/BuyCrypto";

// export const ROOT_WALLET = "0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266";

const NFT_NAME = "ClaimableRewardNftV2";

const InitialWalletsState = (): Map<string, Wallet> => {
  const m = new Map<string, Wallet>();
  // m.set(ROOT_WALLET, {
  //   name: "Warehouse",
  //   address: ROOT_WALLET,
  //   balances: [],
  // });
  return m;
};

export interface AppProps {
  rewardTokenName: string;
}

const fetchTokens = async (accessToken: string): Promise<Array<Token>> => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}` || "anonymous",
    },
  };
  const response = await fetch(
    `${apiEndpoint}/api/v1/tokens`,
    requestOptions
  );
  const dataResponse: { tokens: Array<Token> } = await response.json();
  return dataResponse.tokens;
}

function App(props: AppProps) {
  const { isAuthenticated, isLoading, user, getAccessTokenSilently } =
    useAuth0();
  const [accessToken, setAccessToken] = useState<string>("");

  const navigate = useNavigate();
  const [contractAddresses, setContractAddresses] = useState<
    Map<string, ContractAndSupply>
  >(new Map());
  const [contractAddressesList, setContractAddressesList] = useState<
    Array<ContractAndSupply>
  >([]);

  const [wallets, setWallets] = useState<Map<string, Wallet>>(
    InitialWalletsState()
  );

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      getAccessTokenSilently()
        .then((token: string) => {
          setAccessToken(token);
        })
        .catch(() => {
          setAccessToken("anonymous");
        });
    }
  });

  const getSupply = async (contract: Contract) => {
    // const contractBalance: Array<ContractAndBalance> =
    //   await inventoryService.getAssetBalance(
    //     "ERC20",
    //     Array.from(contractAddresses.values()),
    //     ROOT_WALLET
    //   );
    if (!contractAddresses.has(contract.address)) {
      const contractWithSupply: ContractAndSupply =
        user && user.email
          ? await inventoryService.getAssetSupply(accessToken, contract)
          : { ...contract, supply: NaN };
      const withUpdate = contractAddresses.set(
        contract.address,
        contractWithSupply
      );
      const fromOldMap = new Map(withUpdate);
      setContractAddresses(fromOldMap);
      // setContractBalanceTotal(contractBalance.length);
    }
  };

  const transfer = async (
    contract: Contract,
    from: Wallet,
    to: WalletId,
    amount: number
  ) => {
    const data: TransferRequest = {
      tokenName: contract.name,
      contractAddress: contract.address,
      amount: amount,
      from: from.address,
      to: to.address,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}` || "anonymous", // JWT fetched from auth0
      },
      body: JSON.stringify(data),
    };
    const response = await fetch(
      `${apiEndpoint}/api/v1/transfer`,
      requestOptions
    );
    await response.json();

    await getSupply(contract);
    await refreshBalances();
  };

  const updateWallet = async (wallet: Wallet) => {
    wallets.set(wallet.address, {
      name: wallet.name,
      address: wallet.address,
      balances: wallet.balances,
    });
    const fromOldMap = new Map(wallets);
    setWallets(fromOldMap);
  };

  const onWalletCreated = async (wallet: Wallet) => {
    await updateWallet(wallet);
    await refreshBalances();
  };

  useEffect(() => {
    setContractAddressesList(Array.from(contractAddresses.values()));
  }, [contractAddresses]);

  const getTokens = async (authToken: string) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}` || "anonymous",
      },
    };
    const response = await fetch(
      `${apiEndpoint}/api/v1/tokens`,
      requestOptions
    );
    const dataResponse: { tokens: Array<Token> } = await response.json();

   for (let i = 0; i < dataResponse.tokens.length; i++) {
    const token = dataResponse.tokens[i];
    await getSupply({
      address: token.contractAddress,
      name: token.contractName,
      ticker: token.tokenTicker,
      tokenType: token.contractType,
    });
   }
  };

  const refreshBalances = async () => {
    const walletArray: Wallet[] = Array.from(wallets.values());
    const walletsAndBalances: Array<Wallet> = await Promise.all(
      walletArray.map(async (wallet) => {
        const contracts = Array.from(contractAddresses.values());
        const balance: Array<ContractAndBalance> = accessToken
          ? await inventoryService.getAssetBalance(
              accessToken,
              contracts,
              wallet.address
            )
          : [];
        return {
          name: wallet.name,
          address: wallet.address,
          balances: balance,
        };
      })
    );

    walletsAndBalances.forEach((wab) => {
      wab.balances.map((balance) => {
        getSupply(balance.contract);
      });
      updateWallet(wab);
    });
  };

  useEffect(() => {
    refreshBalances();
  }, [contractAddresses]);

  useEffect(() => {
    if (accessToken) {
      getTokens(accessToken);
    }
  }, [accessToken]);

  useEffect(() => {
    if (accessToken) {
      refreshBalances();
    }
  }, [wallets]);

  if (isAuthenticated) {
    return (
      <div>
        <div>
          <Routes>
            <Route
              path="/"
              element={
                <Home
                  navigate={navigate}
                />
              }
            />
            <Route
              path="/buycrypto"
              element={
                <BuyCrypto
                  title={"MobileHome"}
                  owner={user?.nickname || "owner"}
                  apiEndpoint={apiEndpoint}
                  contracts={contractAddressesList}
                  inventoryService={inventoryService}
                  accessToken={accessToken}
                  rewardTokenName={props.rewardTokenName}
                />
              }
            />
            <Route
              path="/tictactoken"
              element={
                <MobileHome
                  title={"MobileHome"}
                  owner={user?.nickname || "owner"}
                  apiEndpoint={apiEndpoint}
                  contracts={contractAddressesList}
                  inventoryService={inventoryService}
                  accessToken={accessToken}
                  rewardTokenName={props.rewardTokenName}
                />
              }
            />
            <Route
              path="inventory"
              element={
                <>
                  <NavBar user={user} />
                  <InventOrEe
                    onAssetsMoved={getSupply}
                    contractAddresses={contractAddresses}
                    onWalletCreated={onWalletCreated}
                    refreshBalances={refreshBalances}
                    transfer={transfer}
                    wallets={wallets}
                    apiEndpoint={apiEndpoint}
                    accessToken={accessToken}
                    user={user}
                  />
                </>
              }
            />
            <Route
              path="lotto"
              element={
                <>
                  <NavBar user={user} />
                  <RedeemPage
                    inventoryService={inventoryService}
                    contracts={contractAddressesList}
                    apiEndpoint={apiEndpoint}
                    fractalEndpoint={fractalEndpoint}
                    accessToken={accessToken}
                    rewardTokenName={props.rewardTokenName}
                    nftName={NFT_NAME}
                  />
                </>
              }
            />
            <Route
              path="memory"
              element={
                <TicTacToken
                  inventoryService={inventoryService}
                  contracts={contractAddressesList}
                  apiEndpoint={apiEndpoint}
                  accessToken={accessToken}
                />
              }
            />
            <Route
              path="myTokensAndNFTs"
              element={
                <TokenAndNftCollection
                  inventoryService={inventoryService}
                  contracts={contractAddressesList}
                  apiEndpoint={apiEndpoint}
                  fractalEndpoint={fractalEndpoint}
                  accessToken={accessToken}
                  nftName={NFT_NAME}
                />
              }
            />
            <Route
              path="dashboard"
              element={
                <BankVaultDashBoard
                  tokensInCirculation={
                    contractAddressesList ? contractAddressesList : undefined
                  }
                  fractalApiEndpoint={fractalEndpoint}
                  accessToken={accessToken}
                  getRecentlySoldCollectibles={getRecentlySoldCollectibles}
                  rewardTokenName={props.rewardTokenName}
                />
              }
            />

            <Route path="reports" element={<Home navigate={navigate} />} />

            <Route
              path="gallery"
              element={
                <Gallery
                  getUsersCollectibles={getCollectibles}
                  fetchTokens={fetchTokens}
                  getWallets={getWallets}
                  fractalApiEndpoint={fractalEndpoint}
                  apiEndpoint={apiEndpoint}
                  accessToken={accessToken}
                  contracts={contractAddressesList}
                  inventoryService={inventoryService}
                  rewardTokenName={props.rewardTokenName}
                />
              }
            />
          </Routes>
        </div>
      </div>
    );
  } else if (isLoading) {
    return (
      <div>
        <p>Loading</p>
      </div>
    );
  } else {
    return (
      <div>
        <LoginPage />
      </div>
    );
  }
}

export default App;
