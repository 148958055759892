import { Dialog } from "@dnb/eufemia";
import { DnbButton } from "../DnbButton";
import { useNavigate } from "react-router-dom";

export const InstructionModal = ({
  modalIsActive,
}: {
  modalIsActive: boolean;
}) => {
  const navigate = useNavigate();

  return (
    <Dialog
      fullscreen={false}
      openState={modalIsActive}
      labelledBy="custom-triggerer"
      omitTriggerButton={true}
    >
      <Dialog.Header>
        <h1 className="font-hore text-4xl flex justify-center pb-5">
          How to play
        </h1>
      </Dialog.Header>
      <Dialog.Header>
        <p className="font-dnb_regular  text-center">
          Flip the tiles to find pairs. If you manage to find four different
          pairs before the timer has finished (10 seconds) you will earn 8
          reward tokens.
        </p>
      </Dialog.Header>
      <div>
        <img
          src="./instruction.svg"
          alt="Game instruction"
          className="m-auto py-5"
        />
      </div>
      <p className="font-dnb_regular text-center">Oh, and avoid the bomb!</p>
      <div>
        <img src="./bomb.svg" alt="Game bomb" className="m-auto py-5" />
      </div>
      <div className="flex justify-center">
        <DnbButton text="READY" onClick={() => navigate("/memory")} />
      </div>
    </Dialog>
  );
};
