import { useEffect, useState } from "react";
import { useInterval } from "usehooks-ts";

export interface HighScoreRewardToken {
  user_id: string;
  email: string;
  balance: number;
}
export const getHighScoreTokenImpl = async (): Promise<
  HighScoreRewardToken[]
> => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(
    `${apiEndpoint}/api/v1/highscore/highscore-rwd-token`,
    requestOptions
  );
  const dataResponce: HighScoreRewardToken[] = await response.json();
  return dataResponce;
};

export const getHighScoreNFTImpl = async (): Promise<
  HighScoreRewardToken[]
> => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(
    `${apiEndpoint}/api/v1/highscore/highscore-nft-token`,
    requestOptions
  );
  const dataResponce: HighScoreRewardToken[] = await response.json();
  return dataResponce;
};

export const apiEndpoint = `${process.env.REACT_APP_API_ENDPOINT}`;

export interface HighScoresProps {
  getHighScoreNFT: () => Promise<HighScoreRewardToken[]>;
  getHighScoreToken: () => Promise<HighScoreRewardToken[]>;
}

export const usernameFromEmail = (email: string) => {
  return email.split("@", 1)[0];
};

export const HighScores = (props: HighScoresProps) => {
  const { getHighScoreNFT, getHighScoreToken } = props;
  const [highScoresToken, setHighScoresToken] = useState<
    Array<HighScoreRewardToken>
  >([]);

  const [highScoresNFT, setHighScoresNFT] = useState<
    Array<HighScoreRewardToken>
  >([]);

  function cleanAndFilterUsers(highscoreReward: HighScoreRewardToken[]) {
    const newresultNFT = highscoreReward.filter(
      (item) => item.email !== "admin@tiktaktoken.com"
    );

    const removeDomainresultNFT = newresultNFT.map((item) => {
      const updatedToken: HighScoreRewardToken = {
        ...item,
        email: usernameFromEmail(item.email),
      };
      return updatedToken;
    });
    return removeDomainresultNFT;
  }

  async function callGetHighScoreToken() {
    const resultToken = await getHighScoreToken();
    setHighScoresToken(cleanAndFilterUsers(resultToken));
  }

  async function callGetHighScoreNFT() {
    const resultNFT = await getHighScoreNFT();
    setHighScoresNFT(cleanAndFilterUsers(resultNFT));
  }

  useEffect(() => {
    callGetHighScoreToken();
  }, [setHighScoresToken]);

  useEffect(() => {
    callGetHighScoreNFT();
  }, [setHighScoresNFT]);

  useInterval(() => {
    callGetHighScoreToken();
    callGetHighScoreNFT();
  }, 5000);

  return (
    <div>
      <h2 className="font-hore text-2xl text-white mb-3 ">HI-Score {}</h2>
      <div className="border-sea-green border rounded-md bg-black bg-opacity-40 p-4 mb-6">
        <div className="pt-3 pb-2 text-summer-green font-dnb_bold border-sea-green border-b mb-2 text-xs">
          THE MOST CULTURED (NFT)
        </div>
        <div id="height-scores">
          {highScoresToken
            .sort((a, b) => b.balance - a.balance)
            .map((highscore: HighScoreRewardToken, i) => (
              <div
                key={i}
                className="grid text-white grid-cols-2 gap-2 pr-4 pl-4 pb-1 text-xs"
              >
                <div className="text-left">{highscore.email}</div>
                <div className="text-right">{highscore.balance}</div>
              </div>
            ))}
        </div>
      </div>
      <div className="border-sea-green border rounded-md bg-black bg-opacity-40 p-4 mb-6">
        <div className="pt-3 pb-2 text-summer-green font-dnb_bold border-sea-green border-b mb-2 text-xs">
          THE RICH LIST (TOKEN)
        </div>
        {highScoresNFT
          .sort((a, b) => b.balance - a.balance)
          .map((highscore: HighScoreRewardToken, i) => (
            <div
              key={i}
              className="grid text-white grid-cols-2 gap-2 pr-4 pl-4 pb-1 text-xs"
            >
              <div className="text-left">{highscore.email}</div>
              <div className="text-right">{highscore.balance}</div>
            </div>
          ))}
      </div>
    </div>
  );
};
