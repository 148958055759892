import { Resolver, useForm } from "react-hook-form";
import { Wallet } from "../../models/ApplicationModels";

type FormValues = {
  name: string;
  address: string;
};

const resolver: Resolver<FormValues> = async (values) => {
  return {
    values: values.name ? values : {},
    errors: !values.name
      ? {
          name: {
            type: "required",
            message: "This is required.",
          },
        }
      : {},
  };
};

interface AddWalletFormProps {
  onWalletAdded: (contract: Wallet) => void;
}

const AddWalletForm = (props: AddWalletFormProps) => {
  const { onWalletAdded } = props;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({ resolver });

  const onSubmit = handleSubmit(async (data) => {
    // const requestOptions = {
    //     method: 'POST',
    //     headers: { 'Content-Type': 'application/json' },
    //     body: JSON.stringify(data)
    // };
    // const response = await fetch('http://localhost:8000/api/v1/wallets/create', requestOptions);
    // const dataResponse = await response.json();

    // we just add locally for now
    onWalletAdded({
      name: data.name,
      address: data.address,
      balances: [],
    });
  });

  return (
    <div>
      <form
        onSubmit={onSubmit}
        className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
      >
        <div className="mb-4">
          <label
            className="block text-black-80 text-sm font-bold mb-2"
            htmlFor="username"
          >
            Name
          </label>
          <input
            {...register("name", { required: true })}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-black-80 leading-tight focus:outline-none focus:shadow-outline"
            id="username"
            type="text"
            placeholder="Wallet Name"
          />
          {errors.name && <p>Required field</p>}
        </div>
        <div className="mb-4">
          <label
            className="block text-black-80 text-sm font-bold mb-2"
            htmlFor="username"
          >
            Address
          </label>
          <input
            {...register("address", { required: true })}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-black-80 leading-tight focus:outline-none focus:shadow-outline"
            id="username"
            type="text"
            placeholder="Wallet Address"
          />
          {errors.address && <p>Required field</p>}
        </div>
        <div className="flex items-center justify-between">
          <input
            type="submit"
            className="bg-sea-green font-dnb_regular text-lg text-white border-2 border-sea-green rounded-full px-8 py-0.5 hover:bg-white hover:text-ocean-green"
          />
        </div>
      </form>
    </div>
  );
};

export default AddWalletForm;
