import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DnbButton } from "../components/DnbButton";
import { InstructionModal } from "../components/InstructionModal";
import {
  Contract,
  ContractAndBalance,
  Token,
} from "../models/ApplicationModels";
import { InventoryServiceInterface } from "../service/InventoryService";
import { getWallets } from "../service/UserService";
import { LogoutButton } from "../components/LogoutButton";

interface MobileHomeProps {
  title: string;
  owner: string;
  contracts: Contract[];
  inventoryService: InventoryServiceInterface;
  apiEndpoint: string;
  accessToken: string;
  rewardTokenName: string;
}

export const MobileHome = (props: MobileHomeProps) => {
  const { accessToken } = props;
  const navigate = useNavigate();
  const [modalIsActive, setModalState] = React.useState(false);
  const [wallets, setWallets] = useState<Array<string>>([]);
  const [contractAndBalance, setContractAndBalance] = useState<
    Array<ContractAndBalance>
  >([]);
  const [tokens, setTokens] = useState<Array<Token>>([]);
  const [myRewardBalance, setMyRewardBalance] = useState<string | undefined>(
    undefined
  );

  const tokenName = props.rewardTokenName.split(/(?=[A-Z])/).join(' ').toUpperCase() + 'S'

  // Getting wallets
  useEffect(() => {
    if (accessToken && wallets.length === 0) {
      getWallets(props.apiEndpoint, accessToken).then((wallets) => {
        setWallets(wallets);
      });
    }
  }, [accessToken]);

  // Getting tokens:
  useEffect(() => {
    if (accessToken && tokens.length === 0) {
      getCryptoAssetTokens();
    }
  }, [accessToken]);

  // Get balance
  useEffect(() => {
    if (wallets.length > 0 && accessToken && tokens.length > 0) {
      const rewardToken = tokens.filter(
        (i) => i.contractName === props.rewardTokenName
      );
      const rewardContract: Contract = {
        address: rewardToken[0].contractAddress,
        name: rewardToken[0].contractName,
        ticker: rewardToken[0].tokenTicker,
        tokenType: rewardToken[0].contractType,
      };

      props.inventoryService
        .getAssetBalance(accessToken, [rewardContract], wallets[0])
        .then(setContractAndBalance);
    }
  }, [wallets, tokens]);

  // Set balance
  useEffect(() => {
    const tokens = contractAndBalance.filter(
      (i) => i.contract.name === props.rewardTokenName
    );
    if (tokens && tokens.length > 0) {
      setMyRewardBalance("" + tokens[0].balance);
    }
  }, [contractAndBalance]);

  const getCryptoAssetTokens = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}` || "anonymous",
      },
    };
    const response = await fetch(
      `${props.apiEndpoint}/api/v1/tokens`,
      requestOptions
    );
    const dataResponse: { tokens: Array<Token> } = await response.json();
    setTokens(dataResponse.tokens);
  };

  return (
    <div className="text-center bg-spiral bg-cover bg-center">
      <div className="flex flex-center flex-col">
        <div>
          <img
            src="./TicTacTokenLogo.png"
            alt="Tic Tac Token logo"
            className="w-80 m-auto"
          />
        </div>
        <h1 className="font-dnb_bold text-2xl text-mint-green pt-6">
          Hello {props.owner}!
        </h1>

        <div className="flex-col m-auto w-10/12 py-5 max-w-md">
          <div className="flex-col bg-summer-green  px-8 z-100 rounded-t-lg pt-2">
            <h5 className="font-hore text-xl text-white">your</h5>
            <h2 className="font-hore text-3xl text-white  pb-3">wallet</h2>
          </div>
          <div className="bg-[#011518] z-1  bg-opacity-50 py-10  rounded-b-lg">
            {myRewardBalance ? (
              <h1 className="font-dnb_bond text-8xl text-white">
                {myRewardBalance}
              </h1>
            ) : (
              <h1 className="font-dnb_bond text-4xl text-white p-5">
                {" "}
                Loading...
              </h1>
            )}

            <h1 className="font-dnb_regular text-2xl text-mint-green">
              { tokenName }
            </h1>
          </div>
        </div>
        <p className="pb-5">
          <DnbButton
            text="PLAY GAME"
            onClick={() => setModalState((s) => !s)}
          />
          <InstructionModal modalIsActive={modalIsActive} />
        </p>
        <p className="pb-5">
          <DnbButton text="NFT SHOP" onClick={() => navigate("/gallery")} />
        </p>
        <p className="pb-5">
          <DnbButton
            text="YOUR NFTs"
            onClick={() => navigate("/myTokensAndNFTs")}
          />
        </p>
        <p className="pb-5">
          <LogoutButton />
        </p>
        <div className="m-auto pb-20">
          <img
            src="./dnb-logo.png"
            alt="dnb logo"
            className="float-right w-14 m-5 "
          />
        </div>
      </div>
    </div>
  );
};
