import { Dialog } from "@dnb/eufemia";
import { useState } from "react";
import { ListCollectableRequest } from "../../models/ApiModels";
import { Frame } from "../../pages/Gallery";

interface InsideMyNftProps {
  modalIsActive: boolean;
  collectableSeed: string;
  fractalApiEndpoint: string;
  on_close: () => void;
  accessToken: string;
  contractId: string;
  currentPrice: number | "NOT_FOR_SALE";
  tokenId: number;
}

export const InsideMyNft = ({
  modalIsActive,
  collectableSeed,
  fractalApiEndpoint,
  on_close,
  accessToken,
  contractId,
  currentPrice,
  tokenId,
}: InsideMyNftProps) => {
  const [collactableRequest, setCollectableRequest] =
    useState<ListCollectableRequest>({
      requestId: "",
      price: 0,
      contractAddress: contractId,
      tokenId: tokenId,
    });
  const [listed, setListed] = useState<boolean>(
    currentPrice !== "NOT_FOR_SALE"
  );
  const [deListed, setDeListed] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCollectableRequest({
      requestId: collectableSeed,
      price: parseInt(event.target.value),
      contractAddress: contractId,
      tokenId: tokenId,
    });
    console.log("tokenId", tokenId);
  };
  const editPrice = () => {
    setCollectableRequest({
      requestId: "",
      price: 0,
      contractAddress: contractId,
      tokenId: tokenId,
    });
    setListed(false);
  };

  // const handleSell = async (
  //   req: ListCollectableRequest,
  //   accessToken: string | undefined
  // ) => {
  //   const sellResponse = await sell(req, accessToken);
  //   if (sellResponse.status === 200) {
  //     setListed(true);
  //   }
  // };

  async function Sell(
    req: ListCollectableRequest,
    accessToken: string | undefined
  ) {
    const apiEndpoint = `${process.env.REACT_APP_API_ENDPOINT}`;
    const requestOptions = {
      method: "Post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}` || "anonymous",
      },
      body: JSON.stringify(req),
    };

    const response = await fetch(
      `${apiEndpoint}/api/v1/collectibles/${req.contractAddress}/list`,
      requestOptions
    );

    if (response.status === 200) {
      setListed(true);
    }
  }

  async function DeList(
    req: ListCollectableRequest,
    accessToken: string | undefined
  ) {
    const apiEndpoint = `${process.env.REACT_APP_API_ENDPOINT}`;
    const requestOptions = {
      method: "Get",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}` || "anonymous",
      },
    };
    const response = await fetch(
      `${apiEndpoint}/api/v1/collectibles/${req.requestId}/delist`,
      requestOptions
    );
    if (response.status === 200) {
      setListed(false);
      setDeListed(true);
    }
  }

  // const handleDelist = async (
  //   req: ListCollectableRequest,
  //   accessToken: string | undefined
  // ) => {
  //   const sellResponse = await deList(req, accessToken);
  //   if (sellResponse.status === 200) {
  //     setListed(false);
  //     setDeListed(true);
  //   }
  // };

  return (
    <Dialog
      variant="confirmation"
      fullscreen={false}
      openState={modalIsActive && !deListed}
      labelledBy="custom-triggerer"
      omitTriggerButton={true}
      confirmText={listed ? "Close" : "CANCEL"}
      hideDecline
      onConfirm={on_close}
    >
      <div className="mb-2">
        <Frame
          collectable={{
            id: collectableSeed,
            seed: collectableSeed,
          }}
          fractalApiEndpoint={fractalApiEndpoint}
        />
        {listed ? (
          <div className="bg-accent-yellow rounded m-auto justify-center w-4/6 font-dnb_regular text-xs p-2 -mt-10">
            Added to the marketplace
          </div>
        ) : null}
      </div>

      {listed ? (
        // <div className="w-4/6 m-auto justify-center ">
        <>
          <h1 className="font-dnb_bold text-2xl flex justify-center pb-2 mt-5">
            Fractal tree
          </h1>{" "}
          <div className="bg-mint-green-25 rounded p-2">
            <div className="bg-accent-yellow p-5 my-5 mx-2 rounded">
              <p className="font-dnb_bold">NFT on marketplace</p>
              <p className="font-dnb_regular">
                This NFT has been listed for {collactableRequest.price} tokens
              </p>
            </div>
            <div className="grid grid-cols-2 gap-2 place-content-center m-auto">
              <div className="text-xs  ">
                <button
                  onClick={editPrice}
                  className="bg-white text-sea-green font-dnb_regular rounded-full py-2 px-6 border-2 border-sea-green hover:bg-sea-green hover:text-white hover:border-sea-green focus:bg-mint-green"
                >
                  Edit price
                </button>
              </div>

              <div className="text-xs  justify-center justify-self-center mx-auto">
                {" "}
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    DeList(collactableRequest, accessToken);
                  }}
                  className="bg-white text-sea-green font-dnb_regular rounded-full py-2 px-6 border-2 border-sea-green hover:bg-sea-green hover:text-white hover:border-sea-green focus:bg-mint-green"
                >
                  {" "}
                  Take back NFT
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        // </div>
        <>
          <h1 className="font-dnb_bold text-2xl flex justify-center pb-2">
            Please set your price
          </h1>
          <p className="font-dnb_regular px-3 pb-4">
            Specify the amount of tokens you want to sell your NFT for
          </p>
          <div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                Sell(collactableRequest, accessToken);
              }}
            >
              <input
                title="Prize"
                type="number"
                className="border border-ocean-green rounded p-1 "
                onChange={handleChange}
              ></input>
              <div className="mt-6 text-lg">
                <button
                  type="submit"
                  className="bg-sea-green text-white font-dnb_regular rounded-full py-2.5 px-9 border-2 border-white hover:bg-white hover:text-sea-green hover:border-sea-green focus:bg-mint-green"
                >
                  List for sale
                </button>
              </div>
            </form>
          </div>
        </>
      )}
    </Dialog>
  );
};
