import { Dialog, Button } from "@dnb/eufemia";
import { DnbButton } from "../DnbButton";
import { useNavigate } from "react-router-dom";
import React from "react";

export const GameModal = ({
  modalIsActive,
  setModalIsActive,
  type,
  tokens,
  pairs,
}: {
  modalIsActive: boolean;
  setModalIsActive: React.Dispatch<React.SetStateAction<boolean>>;
  type: string;
  tokens?: number;
  pairs?: number;
}) => {
  const goHome = () => navigate("/");
  const navigate = useNavigate();
  if (type === "win") {
    return (
      <>
        <Dialog
          variant="confirmation"
          omitTriggerButton={true}
          confirmText="Back to the main page"
          hideDecline
          openState={modalIsActive}
          onConfirm={() => {
            goHome();
          }}
          labelledBy="custom-triggerer"
        >
          <h1 className="font-hore text-4xl flex justify-center pb-5">
            Great!
          </h1>
          <div>
            <img
              src="./emojis/party_emoji.svg"
              alt="Game instruction"
              className="m-auto py-5"
            />
          </div>
          <div className="bg-mint-green-25 rounded-md p-4 m-3">
            <p className="font-dnb_bold text-center">
              You got {pairs} pairs and earned {tokens} tokens!
            </p>
            <p className="font-dnb_regular text-center">
              You need 10 token to buy an NFT.
            </p>
          </div>
          <div className="flex justify-center">
            <Button
              text="Try again"
              top="1.5rem"
              size="large"
              onClick={() => window.location.reload()}
            />
          </div>
          <div className="flex justify-center">
            <Button
              size="large"
              top="1.5rem"
              text="To the NFT shop"
              onClick={() => navigate("/gallery")}
            />
          </div>
        </Dialog>
      </>
    );
  } else if (type === "close") {
    return (
      <>
        <Dialog
          variant="confirmation"
          omitTriggerButton={true}
          confirmText="Back to the main page"
          hideDecline
          openState={modalIsActive}
          onConfirm={() => {
            goHome();
          }}
          labelledBy="custom-triggerer"
        >
          <h1 className="font-hore text-4xl flex justify-center pb-5">Auch!</h1>
          <div>
            <img
              src="./emojis/grimace_emoji.svg"
              alt="Game instruction"
              className="m-auto py-5"
            />
          </div>

          <div className="bg-mint-green-25 rounded-md p-4 m-3">
            <p className="font-dnb_bold text-center">
              {" "}
              You earned {tokens} tokens.
            </p>

            <p className="font-dnb_regular text-center">
              Better luck next time!
            </p>
          </div>
          <div className="flex justify-center">
            <Button
              text="Try again"
              top="1.5rem"
              size="large"
              onClick={() => window.location.reload()}
            />
          </div>
        </Dialog>
      </>
    );
  } else if (type === "bomb") {
    return (
      <>
        <Dialog
          variant="confirmation"
          omitTriggerButton={true}
          confirmText="Back to the main page"
          hideDecline
          openState={modalIsActive}
          onConfirm={() => {
            goHome();
          }}
          labelledBy="custom-triggerer"
        >
          <h1 className="font-hore text-4xl flex justify-center pb-5">
            Oh no!
          </h1>
          <div>
            <img
              src="./emojis/bomb_emoji.svg"
              alt="You lost"
              className="m-auto py-5"
            />
          </div>

          <div className="bg-mint-green-25 rounded-md p-4 m-3">
            <p className="font-dnb_bold text-center">You got the BOMB!!</p>
            <p className="font-dnb_regular text-center">
              This means you lost all earnings from this game. But do not
              dispair - try again and rule the crypto universe!
            </p>
          </div>
          <div className="flex justify-center">
            <Button
              text="Try again"
              top="1.5rem"
              size="large"
              onClick={() => window.location.reload()}
            />
          </div>
        </Dialog>
      </>
    );
  } else {
    return (
      <>
        <Dialog
          variant="confirmation"
          omitTriggerButton={true}
          confirmText="Back to the main page"
          hideDecline
          openState={modalIsActive}
          onConfirm={() => {
            goHome();
          }}
          labelledBy="custom-triggerer"
        >
          <h1 className="font-hore text-4xl flex justify-center pb-5">
            Oh no!
          </h1>
          <div className="bg-mint-green-25 rounded-md p-4 m-3">
            <p className="font-dnb_bold text-center">Something went wrong...</p>
            <p className="font-dnb_regular text-center">
              Go back to the main page and try again.
            </p>
          </div>
        </Dialog>
      </>
    );
  }
};
