import { Resolver, useForm } from "react-hook-form";
import { Contract } from "../../models/ApplicationModels";

type FormValues = {
  tokenType: string;
  tokenName: string;
  tokenTicker: string;
};

const resolver: Resolver<FormValues> = async (values) => {
  return {
    values: values.tokenName ? values : {},
    errors: !values.tokenName
      ? {
          tokenName: {
            type: "required",
            message: "This is required.",
          },
        }
      : {},
  };
};

interface CreateAssetFormProps {
  addAssetContract: (contract: Contract) => void;
  apiEndpoint: string;
  accessToken: string;
  user: { email?: string } | undefined;
}

const CreateAssetForm = (props: CreateAssetFormProps) => {
  const { addAssetContract, user } = props;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({ resolver });

  const onSubmit = handleSubmit(async (data) => {
    const { accessToken } = props;
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
      Authorization: `Bearer ${accessToken}` || "anonymous",
    };
    const response = await fetch(
      `${props.apiEndpoint}/api/v1/tokens/create-token`,
      requestOptions
    );
    const dataResponse = await response.json();
    addAssetContract({
      address: dataResponse.contractAddress,
      name: data.tokenName,
      ticker: data.tokenTicker,
      tokenType: data.tokenType
    });
  });

  return (
    <div>
      <form
        onSubmit={onSubmit}
        className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
      >
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="username"
          >
            Type
          </label>
          <select
            {...register("tokenType", { required: true })}
            id="tokenType"
            name="tokenType"
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          >
            <option value="ERC20">ERC20</option>
            <option value="NFT">NFT</option>
            <option value="TokenSwap">Swappable NFT</option>
          </select>
          {/* <input 
                        {...register('tokenName', { required: true })}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" 
                        id="username" 
                        type="select" 
                        placeholder="Token name" /> */}
          {errors.tokenName && <p>Required field</p>}
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="username"
          >
            Name
          </label>
          <input
            {...register("tokenName", { required: true })}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="username"
            type="text"
            placeholder="Token name"
          />
          {errors.tokenName && <p>Required field</p>}
        </div>
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="username"
          >
            Ticker
          </label>
          <input
            {...register("tokenTicker", { required: true })}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="username"
            type="text"
            placeholder="Token ticker"
          />
          {errors.tokenTicker && <p>Required field</p>}
        </div>
        <div className="flex items-center justify-between">
          <input
            type="submit"
            className="bg-sea-green font-dnb_regular text-lg text-white border-2 border-sea-green rounded-full px-8 py-0.5 hover:bg-white hover:text-ocean-green"
          />
        </div>
      </form>
    </div>
  );
};

export default CreateAssetForm;
