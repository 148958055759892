import { NftDetails, TokenType } from "./ApplicationModels";

export interface TransferRequest {
  tokenName: string;
  contractAddress: string;
  amount: number;
  from: string;
  to: string;
}

export interface Prize {
  location: number;
  value: number;
}

export interface RewardResponse {
  prizes: Array<Prize>;
  prizeAwarded: Prize;
}

export enum GameState {
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETE = "COMPLETE",
}

export interface ApiMemoryGameState {
  gameToken: string;
  sequenceId: number;
  pairs: Array<number>;
  tileValues: Array<number | undefined>;
  currentlySelected: number | undefined;
  numberOfTries: number;
  state: GameState;
  reward: number | undefined;
  maxTries: number;
}

export enum GameStateTimedMemory {
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETE = "COMPLETE",
  BOMBED = "BOMBED",
}
export interface ApiTimedMemoryGameState {
  gameToken: string;
  sequenceId: number;
  pairs: Array<number>;
  tileValues: Array<number | undefined>;
  currentlySelected: number | undefined;
  numberOfTries: number;
  state: GameStateTimedMemory;
  reward: number | undefined;
  maxTimeTicks: number;
}

export interface MemoryChooseRequest {
  gameToken: string;
  sequenceId: number;
  choice: number;
}

export interface Collectable {
  id: string;
  seed: string;
  url: string;
  owner_id?: string;
  price?: number;
}

export interface AccountAndBalance {
  accountNumber: string;
  balance: number;
  currency: "NOK"
}

export interface GetBankBalanceResponse {
  accounts: Array<AccountAndBalance>
}

export interface GetBalanceResponse {
  tokenType: TokenType;
  contractAddress: string;
  tokenName: string;
  balance: number;
  tokenUris?: NftDetails[];
  decimals: number;
}

export interface BuyCollectableRequest {
  purchaseId: string; // for idempotency
  collectableId: string;
}

export interface ListCollectableRequest {
  requestId: string; // for idempotency
  price: number;
  contractAddress: string;
  tokenId: number;
}

export interface DelistCollectableRequest {
  requestId: string; // for idempotency
}

export interface BuyCollectableResponse {
  purchaseId: string; // for idempotency
  collectableId: string;
  tokenId: string;
  url: string;
}
