import React, { createContext, useState } from 'react';
import { IntlProvider } from 'react-intl'
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { InventoryService } from './service/InventoryService';
import { Auth0Provider } from '@auth0/auth0-react';

import { Analytics } from 'analytics';
import googleAnalytics from '@analytics/google-analytics'

import messages_no from "./translations/no.json";
import messages_en from "./translations/en.json";

import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { cleanEnv, str, bool } from 'envalid';
import { getTelemetryPlugin } from './AppInsights';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

interface ApplicationContext {
  setUser: (_: string) => void;
  userName: string | undefined;
}

interface ApplicationEmailContext {
  setUserEmail: (_: string) => void;
  userEMail: string | undefined;
}

const DefaultContext = { 
  setUser: (_: string) => { return undefined },
  userName: undefined,
}

const EmailContext = { 
  setUserEmail: (_: string) => { return undefined },
  userEMail: undefined
}

export const getEnvVars = (env: NodeJS.ProcessEnv) => {
  return cleanEnv(env, {
      REACT_APP_API_ENDPOINT:           str(),
      REACT_APP_FRACTAL_ENDPOINT:       str(),
      REACT_APP_TELEMETRY_CONNECTION:   str(),
      REACT_APP_TELEMETRY_DISABLED:     bool({default: false}), 
      REACT_APP_REWARD_TOKEN_TO_USE:    str(), 
    })
}

const envVars = getEnvVars(process.env);

export const apiEndpoint = envVars.REACT_APP_API_ENDPOINT;
export const fractalEndpoint = envVars.REACT_APP_FRACTAL_ENDPOINT;

export const UserContext = createContext<ApplicationContext>(DefaultContext);
export const UserEmailContext = createContext<ApplicationEmailContext>(EmailContext);

export const inventoryService = new InventoryService(apiEndpoint);

const messages: Record<string, Record<string,string>> = {
  'no': messages_no,
  'en': messages_en
};

const language = navigator.language.split(/[-_]/)[0];  // language without region code

const analytics = Analytics({
  app: 'tic-tac-token',
  plugins: [
    googleAnalytics({
      measurementIds: ['G-4TWV3QVQNJ']
    })
  ]
})

export const ContextWrapper = () => {
  const [userToken, setUserToken] = useState<string | undefined>(undefined);

  return(
    <AppInsightsContext.Provider value={getTelemetryPlugin(envVars.REACT_APP_TELEMETRY_DISABLED, envVars.REACT_APP_TELEMETRY_CONNECTION)} >
      <Auth0Provider
        domain="cryptolab.eu.auth0.com"
        clientId="vjWVFpq34Y4Z2C4SUPt13QcvgoG9ProX"
        redirectUri={window.location.origin}
        audience="https://api-inventory.anrize.co"
        useRefreshTokens={true}
        cacheLocation="localstorage"
      >
          <UserContext.Provider value={{ userName: userToken, setUser: setUserToken }} >
            <IntlProvider locale={language} messages={messages[language]}>
              <BrowserRouter>
                  <App rewardTokenName={envVars.REACT_APP_REWARD_TOKEN_TO_USE} />
              </BrowserRouter>
            </IntlProvider>
          </UserContext.Provider>
      </Auth0Provider>
    </AppInsightsContext.Provider>
  )
}


root.render(
  <React.StrictMode>
    <ContextWrapper />
  </React.StrictMode>
);

