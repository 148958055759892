import _ from "lodash";
import { useEffect, useState } from "react";
import { Spinner } from "../components/Spinner";
import { InventoryServiceInterface } from "../service/InventoryService";
import {
  MemoryChooseRequest,
  ApiTimedMemoryGameState,
  GameStateTimedMemory,
} from "../models/ApiModels";
import { Timer } from "../components/Timer";
import { Contract } from "../models/ApplicationModels";
import { DnbButton } from "../components/DnbButton";
import { GameModal } from "../components/GameModal";
import { useTimer } from "react-timer-hook";
import { BackButton } from "../components/BackButton";

export interface TicTacTokenProps {
  contracts: Contract[];
  inventoryService: InventoryServiceInterface;
  apiEndpoint: string;
  accessToken: string;
}

export function showShape(
  tileValue: number | undefined,
  state: GameStateTimedMemory | undefined
) {
  if (state === GameStateTimedMemory.BOMBED) {
    return <img src="./bomb.svg" alt="Bomb" />;
  }
  if (tileValue === 0) {
    return <img src="./shapes/Ellipse.svg" alt="Ellipse" />;
  }
  if (tileValue === 1) {
    return <img src="./shapes/Polygon.svg" alt="Polygon" />;
  }
  if (tileValue === 2) {
    return <img src="./shapes/Rectangle.svg" alt="Rectangle" />;
  }
  if (tileValue === 3) {
    return <img src="./shapes/Star.svg" alt="Star" />;
  }
  if (tileValue === 4) {
    return <img src="./shapes/Equal.svg" alt="Equal-sign" />;
  }
  if (tileValue === 5) {
    return <img src="./shapes/Diamond.svg" alt="Diamond" />;
  } else {
    return <div></div>;
  }
}

const mySeconds = (ms: number): Date => {
  const time = new Date();
  time.setMilliseconds(time.getMilliseconds() + ms);
  return time;
};

export const TicTacToken = (props: TicTacTokenProps) => {
  const { accessToken } = props;
  const [gameState, setGameState] = useState<
    ApiTimedMemoryGameState | undefined
  >(undefined);
  const [gameSequenceId, setGameSequenceId] = useState<number>(0);

  const [reward, setReward] = useState<number | undefined>(undefined);

  const [wallets, setWallets] = useState<Array<string>>([]);

  const [usedMaxTries, setUsedMaxTries] = useState<Boolean>(false);
  const [modalIsActive, setModalState] = useState<boolean>(false);
  const {
    start: setTimerStarted,
    isRunning,
    pause,
  } = useTimer({
    expiryTimestamp: mySeconds((gameState?.maxTimeTicks || 10000) + 500),
    onExpire: () => chooseCard(-1),
  });
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, []);

  const createGame = async () => {
    if (accessToken !== undefined) {
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({ numOfCards: 9 }),
      };
      const response = await fetch(
        `${props.apiEndpoint}/api/v1/games/timedmemory`,
        requestOptions
      );

      const data = await response.json();

      setGameState(data);
    }
  };

  useEffect(() => {
    if (accessToken) {
      createGame();
    }
  }, [accessToken]);

  useEffect(() => {
    if (gameState) {
      if (!isRunning) {
        setTimerStarted();
      }
      if (gameState.state === GameStateTimedMemory.COMPLETE) {
        setReward(gameState.reward);
        setUsedMaxTries(true);
        pause();
      }
    }
  }, [gameState]);

  const getWallets = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}` || "anonymous",
      },
    };
    const response = await fetch(
      `${props.apiEndpoint}/api/v1/wallets/my-wallets`,
      requestOptions
    );
    const dataResponse: { wallets: [string] } = await response.json();
    setWallets(dataResponse.wallets);
  };

  const restartGame = () => {
    setGameState(undefined);
    createGame();
  };

  const chooseCard = async (n: number) => {
    await sendChooseCardRequest(n);
  };

  const chooseCardTest = async (n: number) => {
    const chooseData: MemoryChooseRequest = {
      gameToken: "",
      sequenceId: 0,
      choice: 0,
    };
    await callChooseCard("", chooseData, { apiEndpoint: "" });
  };

  async function sendChooseCardRequest(n: number) {
    if (accessToken && gameState && !usedMaxTries) {
      // setSelectedCards([ ... selectedCards,  n ]);

      const chooseData: MemoryChooseRequest = {
        gameToken: gameState.gameToken,
        sequenceId: gameSequenceId,
        choice: n,
      };

      if (accessToken !== undefined) {
        const resp = await callChooseCard(accessToken, chooseData, props);

        setGameState(resp);
        setGameSequenceId(gameSequenceId + 1);
      }
    }
  }

  async function callChooseCard(
    accessToken: string,
    chooseData: any,
    props: any
  ): Promise<ApiTimedMemoryGameState> {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(chooseData),
    };
    const response = await fetch(
      `${props.apiEndpoint}/api/v1/games/timedmemory/choose`,
      requestOptions
    );
    const resp: ApiTimedMemoryGameState = await response.json();

    return resp;
  }

  function getReward(): number {
    if (gameState && gameState.reward !== undefined) {
      return gameState.reward;
    } else {
      return 0;
    }
  }

  // function textForNumberOfTries(
  //   numberOfTries: number | undefined,
  //   maxTries: number | undefined
  // ): string {
  //   if (numberOfTries !== undefined && maxTries !== undefined) {
  //     let triesLeft: number = maxTries - numberOfTries;
  //     if (gameState?.state === GameState.IN_PROGRESS) {
  //       return "You have " + triesLeft + " tries left";
  //     } else if (gameState?.state === GameState.COMPLETE) {
  //       return "Sorry! All out of tries today... come back tomorrow";
  //     } else return "";
  //   } else return "";
  // }

  function pairsFound(pairs: number | undefined): number {
    if (pairs !== undefined) {
      return pairs / 2;
    } else return 0;
  }

  return (
    <div className="bg-spiral bg-cover bg-center h-screen">
      <div className="text-center grid grid-cols-3 gap-2">
        <div>
          <BackButton navigateTo={"/"} />
        </div>
        <div className="pt-8 pb-5">
          <img
            src="./TicTacTokenLogo.png"
            alt="Tic Tac Token logo"
            className="w-60 m-auto"
          />
        </div>
      </div>
      <div className="text-center justify-center">
        {gameState?.state === GameStateTimedMemory.BOMBED ? (
          <GameModal
            type="bomb"
            setModalIsActive={setModalState}
            modalIsActive={true}
          />
        ) : (
          <p className="font-dnb_regular text-white"> </p>
        )}
      </div>
      <div className="font-dnb_bold text-white text-center text-3xl p-2">
        {/* {textForNumberOfTries(gameState?.numberOfTries, gameState?.maxTries)} */}
      </div>
      <div className="px-12">
        <div className="flex justify-center">
          <h2 className=" font-hore text-3xl text-white  pb-3">Timer</h2>
        </div>
        <Timer handleTimeout={() => {}} />
        <p className="pb-10"></p>
        <div hidden={true}>
          <DnbButton text="RESTART_GAME" onClick={restartGame} />
          <DnbButton text="TESTCARDCLICK" onClick={() => chooseCardTest(0)} />
          <DnbButton
            text="TESTCARDPARTS"
            onClick={() => sendChooseCardRequest(0)}
          />
          <DnbButton text="GETWALLETS" onClick={() => getWallets()} />
        </div>
        {gameState?.state === GameStateTimedMemory.COMPLETE ? (
          gameState.pairs.length <= 1 ? (
            <GameModal
              modalIsActive={true}
              setModalIsActive={setModalState}
              tokens={getReward()}
              type={"close"}
            />
          ) : (
            <GameModal
              type="win"
              setModalIsActive={setModalState}
              modalIsActive={true}
              tokens={getReward()}
              pairs={pairsFound(gameState?.pairs.length)}
            />
          )
        ) : null}
      </div>
      <div className="grid grid-cols-3 flex">
        <div className="col-span-3 place-content-center m-auto">
          <div className="grid grid-cols-3 gap-8 pb-20">
            {_.range(9).map((i) => {
              return (
                <div key={i} className="relative ">
                  <div
                    className={`relative rounded-md bg-sea-green aspect-square h-20 md:h-40 lg:h-50 cursor-pointer duration-1000 border-summer-green border ${
                      gameState?.pairs.includes(i) ||
                      (gameState?.currentlySelected === i && "scale-0")
                    } `}
                    onClick={() => {
                      return chooseCard(i);
                    }}
                  ></div>
                  <div
                    className={`absolute object-center flex p-4 border-summer-green border font-bold rounded-md top-0 aspect-square h-20 md:h-40 lg:h-50 w-full bg-white  cursor-pointer  duration-1000 ${
                      gameState?.pairs.includes(i) ||
                      gameState?.currentlySelected === i
                        ? "scale-100"
                        : "scale-0"
                    } `}
                    onClick={() => {
                      return chooseCard(i);
                    }}
                  >
                    {showShape(gameState?.tileValues[i], gameState?.state) || (
                      <Spinner />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
