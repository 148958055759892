import { useState } from "react";
import { Contract, Wallet } from "../../models/ApplicationModels";
import { DnbButton } from "../DnbButton";

export interface WalletId {
  name: string;
  address: string;
}

interface InventoryContainerProps {
  wallet: Wallet;
  otherWallets: Array<WalletId>;
  onTransfer: (
    contract: Contract,
    from: Wallet,
    to: WalletId,
    amount: number
  ) => void;
}

const InventoryContainer = (props: InventoryContainerProps) => {
  const [selectedWallet, setSelectedWallet] = useState<undefined | WalletId>(
    undefined
  );

  return (
    <div className="py-2">
      <span className="bg-sea-green p-1 text-white rounded-t">{props.wallet.name}</span>
      <div className="border-solid border-2 border-sea-green rounded-b rounded-tr">
        {props.wallet.balances.length === 0 && (
          <div key="noassets" className="p-2">
            No assets yet
          </div>
        )}

        {props.wallet.balances.length > 0 && (
          <div className="grid grid-cols-3 p-2 bg-black-20">
            <div className="">Name</div>
            <div>Balance</div>
            <div>transfer</div>
          </div>
        )}

        {props.wallet.balances.length > 0 &&
          props.wallet.balances.map((b) => {
            return (
              <div key={b.contract.address} className="grid grid-cols-3 p-2">
                <div>{b.contract.name}</div>
                <div>{b.balance}</div>
                <div className="grid grid-cols-2">
                  <div className="p-1">
                    <select
                      defaultValue={"wallets"}
                      title="transfer"
                      onChange={(
                        event: React.ChangeEvent<HTMLSelectElement>
                      ) => {
                        event.preventDefault();
                        const walletId = event.target.value;
                        const wallet = props.otherWallets.find(
                          (w) => w.address === walletId
                        );

                        if (wallet) {
                          setSelectedWallet(wallet);
                        }
                      }}
                      id="transfer"
                      name="transfer"
                      className="shadow appearance-none border rounded w-full py-2 px-3 text-black-80 leading-tight focus:outline-none focus:shadow-outline"
                    >
                      <option disabled>wallets</option>
                      {props.otherWallets.map((w) => {
                        return (
                          <option key={w.address} value={w.address}>
                            {w.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="p-1">
                    <DnbButton
                      text="send"
                      onClick={() => {
                        if (selectedWallet) {
                          props.onTransfer(
                            b.contract,
                            props.wallet,
                            selectedWallet,
                            1
                          );
                        }
                      }}
                    />
                    {/* <button
                      onClick={() => {
                        if (selectedWallet) {
                          props.onTransfer(
                            b.contract,
                            props.wallet,
                            selectedWallet,
                            1
                          );
                        }
                      }}
                      className="bg-sea-green hover:bg-ocean-green text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline float-right"
                    >
                      Send
                    </button> */}
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default InventoryContainer;
