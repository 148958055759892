interface DnbButtonProps {
  text: string;
  onClick: () => void;
}

export const DnbButton = (props: DnbButtonProps) => {
  return (
    <button
      type="button"
      className="bg-sea-green font-dnb_regular min-w-max text-lg text-white border-2 border-sea-green rounded-full px-8 py-0.5 hover:bg-white hover:text-ocean-green"
      onClick={props.onClick}
    >
      {props.text}
    </button>
  );
};
