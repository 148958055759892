import { Dialog } from "@dnb/eufemia";
import { useNavigate } from "react-router-dom";
import { Collectable } from "../../models/ApiModels";
import { Frame } from "../../pages/Gallery";
import { DnbButton } from "../DnbButton";

export interface ViewModalProps {
  modalIsActive: boolean;
  title: string;
  subTitle?: string;
  text: string;
  img_source?: string;
  img_alt?: string;
  img_className?: string;
  button_text?: string;
  collectable: Collectable;
  fractalApiEndpoint: string;
  button_onClick?: () => void;

  onClose: () => void;
  balance?: string;
  isAlreadyPurchased?: boolean;
}

export const overTreshToken = (balance: string | undefined): boolean => {
  if (balance !== undefined) {
    if (parseInt(balance) >= 10) {
      return true;
    } else return false;
  } else return false;
};
export const tokensShort = (balance: string | undefined): number => {
  if (balance !== undefined) {
    const res = 10 - parseInt(balance);
    return res;
  } else return 0;
};

export const ViewModal = ({
  modalIsActive,
  title,
  subTitle,
  text,
  img_source,
  img_alt,
  img_className,
  button_text,
  collectable,
  fractalApiEndpoint,
  button_onClick,

  onClose,
  balance,
  isAlreadyPurchased,
}: ViewModalProps) => {
  const navigate = useNavigate();

  return (
    <Dialog
      fullscreen={false}
      openState={modalIsActive}
      labelledBy="custom-triggerer"
      omitTriggerButton={true}
      onClose={(x) => x.triggeredBy === "button" && onClose()}
    >
      <div className="z-9 bg-signal-orange backdrop-blur font-bold -mb-40 mt-40 outline-offset-2 text-center">
        {isAlreadyPurchased ? (
          <div>
            <p className="text-black">No longer available</p>
            <p className="text-black">(somebody just purchased this NFT)</p>
          </div>
        ) : null}
      </div>
      <div>
        <Frame
          collectable={collectable}
          fractalApiEndpoint={fractalApiEndpoint}
        />
      </div>

      <Dialog.Header>
        <h1 className="font-dnb_bold text-4xl flex justify-center pb-2 text-ocean-green">
          {title}
        </h1>
      </Dialog.Header>
      <Dialog.Header>
        <h2 className="font-dnb_bold  text-center pb-4 px-4 text-ocean-green">
          {subTitle}
        </h2>
      </Dialog.Header>

      <Dialog.Header>
        <p className="font-dnb_regular  text-center pb-4 px-4 text-ocean-green">
          {text}
        </p>
      </Dialog.Header>
      {isAlreadyPurchased ? (
        <div className="flex text-center justify-center font-dnb_bold text-ocean-green p-4">
          <DnbButton
            text={"Load more NFTs"}
            onClick={() => window.location.reload()}
          />
        </div>
      ) : overTreshToken(balance) ? (
        <div className="flex justify-center">
          {button_text && button_onClick ? (
            <DnbButton text={button_text} onClick={button_onClick} />
          ) : (
            <p></p>
          )}
        </div>
      ) : (
        <div>
          <div className=" p-4 rounded bg-accent-yellow m-auto w-fit text-center">
            <p className="text-ocean-green font-dnb_bold p-2">
              {" "}
              You are {tokensShort(balance)} short
            </p>
            <p>
              <DnbButton text={"Earn more"} onClick={() => navigate("/")} />
            </p>
          </div>
        </div>
      )}
    </Dialog>
  );
};
