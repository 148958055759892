import _ from "lodash";
import { GetBalanceResponse } from "../models/ApiModels";
import {
  Contract,
  ContractAndBalance,
  ContractAndSupply,
} from "../models/ApplicationModels";

interface TokenSupplyRequest {
  tokenName: string;
  contractAddress: string;
}

interface Token {
  contractName: string;
  contractAddress: string;
}

interface TokenBalanceRequest {
  tokens: Array<Token>;
  wallet: string;
}

export interface InventoryServiceInterface {
  getAssetBalance: (
    userToken: string,
    contracts: Array<Contract>,
    wallet: string
  ) => Promise<Array<ContractAndBalance>>;
  getAssetSupply: (
    userToken: string,
    contract: Contract
  ) => Promise<ContractAndSupply>;
}

export class InventoryService {
  apiEndpoint: string;

  constructor(apiEndpoint: string) {
    this.apiEndpoint = apiEndpoint;
  }

  getAssetBalance = async (
    userToken: string,
    contracts: Array<Contract>,
    wallet: string
  ): Promise<Array<ContractAndBalance>> => {
    if (contracts) {
      const data: TokenBalanceRequest = {
        tokens: contracts.map((c) => {
          return {
            contractName: c.name,
            contractAddress: c.address,
            contractType: c.tokenType,
            tokenTicker: c.ticker,
          };
        }),
        wallet: wallet,
      };
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}` || "anonymous",
        },
        body: JSON.stringify(data),
      };
      const response = await fetch(
        `${this.apiEndpoint}/api/v1/wallets/balance`,
        requestOptions
      );
      const dataResponse: Array<GetBalanceResponse> = await response.json();

      const reduced = _.reduce(
        dataResponse,
        function (sum, n) {
          const foundContract = contracts.find(
            (i) => i.address === n.contractAddress
          );
          // if (foundContract) {
          sum.push({
            tokenType: n.tokenType,
            contract: foundContract || {
              address: n.contractAddress,
              name: n.tokenName,
              ticker: "unknown",
              tokenType: n.tokenType,
            },
            balance: n.balance,
            tokenUris: n.tokenUris,
          });
          // }
          return sum;
        },
        new Array<ContractAndBalance>()
      );

      // const converted: Array<ContractAndBalance> = dataResponse.reduce( (a: Array<ContractAndBalance>, item: ContractAndBalance) => {
      //   const foundContract = contracts.find( (i) => i.address === item.contractAddress )
      //   // return {
      //   //   contract: contracts.find( (i) => i.address === c.contractAddress ),
      //   //   balance: c.balance
      //   // }
      // }, new Array<ContractAndBalance>())

      // const walletBalance = {
      //     contract: contract,
      //     balance: parseInt(dataResponse.hex, 16)
      // }

      return reduced;
    }
    return [];
  };

  getAssetSupply = async (
    userToken: string,
    contract: Contract
  ): Promise<ContractAndSupply> => {
    const data: TokenSupplyRequest = {
      tokenName: contract.name,
      contractAddress: contract.address,
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}` || "anonymous",
      },
      body: JSON.stringify(data),
    };
    const response = await fetch(
      `${this.apiEndpoint}/api/v1/tokens/get-supply`,
      requestOptions
    );
    const dataResponse = await response.json();

    const contractWithSupply = {
      ...contract,
      supply: parseInt(dataResponse.hex, 16),
    };

    return contractWithSupply;
  };
}
