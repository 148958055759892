export const getWallets = async (apiEndpoint: string, accessToken: string): Promise<Array<string>> => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}` || "anonymous",
      },
    };
    const response = await fetch(
      `${apiEndpoint}/api/v1/wallets/my-wallets`,
      requestOptions
    );
    const dataResponse: { wallets: [string] } = await response.json();
    return dataResponse.wallets;
    // setWallets(dataResponse.wallets);
};