import { Resolver, useForm } from "react-hook-form";
import { Wallet } from "../../models/ApplicationModels";

type FormValues = {
  name: string;
};

const resolver: Resolver<FormValues> = async (values) => {
  return {
    values: values.name ? values : {},
    errors: !values.name
      ? {
          name: {
            type: "required",
            message: "This is required.",
          },
        }
      : {},
  };
};

interface CreateWalletFormProps {
  onWalletCreated: (contract: Wallet) => void;
  apiEndpoint: string;
}

const CreateWalletForm = (props: CreateWalletFormProps) => {
  const { onWalletCreated } = props;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({ resolver });

  const onSubmit = handleSubmit(async (data) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    };
    const response = await fetch(
      `${props.apiEndpoint}/api/v1/wallets/create`,
      requestOptions
    );
    const dataResponse = await response.json();
    onWalletCreated({
      name: dataResponse.name,
      address: dataResponse.address,
      balances: [],
    });
  });

  return (
    <div>
      <form
        onSubmit={onSubmit}
        className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4"
      >
        <div className="mb-4">
          <label
            className="block text-gray-700 text-sm font-bold mb-2"
            htmlFor="username"
          >
            Name
          </label>
          <input
            {...register("name", { required: true })}
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            id="username"
            type="text"
            placeholder="wallet name"
          />
          {errors.name && <p>Required field</p>}
        </div>
        <div className="flex items-center justify-between">
          <input
            type="submit"
            className="bg-sea-green font-dnb_regular text-lg text-white border-2 border-sea-green rounded-full px-8 py-0.5 hover:bg-white hover:text-ocean-green"
          />
        </div>
      </form>
    </div>
  );
};

export default CreateWalletForm;
