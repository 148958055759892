import React from 'react';
import { ApplicationInsights, ITelemetryItem } from '@microsoft/applicationinsights-web';
import { ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from "history";

export const getTelemetryPlugin = (disabled: boolean, connectionString: string): ReactPlugin => {
  const browserHistory = createBrowserHistory({ });
  const reactPlugin = new ReactPlugin();
  const appInsights = new ApplicationInsights({
      config: {
          disableTelemetry: disabled,
          connectionString: connectionString,
          extensions: [reactPlugin],
          extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory }
          }
      }
  });
  appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
    envelope.tags = envelope.tags || [];
    envelope.tags.push({ "ai.cloud.role": "ui-inventory.anrize.co" });
  });

  appInsights.loadAppInsights();
  return reactPlugin;
}
