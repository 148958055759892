import { BackButton } from "../components/BackButton";
import { useEffect, useState } from "react";
import { Contract, ContractAndBalance } from "../models/ApplicationModels";
import { InventoryServiceInterface } from "../service/InventoryService";
import { Frame } from "./Gallery";
import { InsideMyNft } from "../components/InsideMyNftModal";
import { Button } from "@dnb/eufemia";

export interface NftCollectionProps {
  contracts: Contract[];
  inventoryService: InventoryServiceInterface;
  apiEndpoint: string;
  fractalEndpoint: string;
  accessToken: string;
  nftName: string;
}

export const TokenAndNftCollection = (props: NftCollectionProps) => {
  const { contracts, inventoryService, accessToken, fractalEndpoint } = props;
  const [myNFTs, setMyNFTs] = useState<ContractAndBalance | "LOADING" | "NONE">(
    "LOADING"
  );
  const [wallets, setWallets] = useState<Array<string>>([]);
  const [contractAndBalance, setContractAndBalance] = useState<
    Array<ContractAndBalance> | undefined
  >(undefined);
  const [activeModal, setActiveModal] = useState<string | undefined>(undefined);

  const getWallets = async () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}` || "anonymous",
      },
    };
    const response = await fetch(
      `${props.apiEndpoint}/api/v1/wallets/my-wallets`,
      requestOptions
    );
    const dataResponse: { wallets: [string] } = await response.json();
    setWallets(dataResponse.wallets);
  };

  const getBalances = async (userToken: string, wallet: string) => {
    const balances = await inventoryService.getAssetBalance(
      userToken,
      contracts,
      wallet
    );
    setContractAndBalance(balances);
  };

  useEffect(() => {
    if (accessToken && wallets.length === 0) {
      getWallets();
    }
  }, [accessToken, wallets]);

  useEffect(() => {
    if (accessToken && wallets.length) {
      getBalances(accessToken, wallets[0]);
    }
  }, [accessToken, wallets, contracts]);

  useEffect(() => {
    if (contractAndBalance) {
      const reward = contractAndBalance.find(
        (i) => i.contract.name === props.nftName
      );
      setMyNFTs(reward || "NONE");
    }
  }, [contractAndBalance]);

  const Placeholder = (props: { text: string }) => {
    return (
      <>
        {" "}
        <div className="text-center bg-spiral bg-cover bg-center">
          <div className="text-center grid grid-cols-3 gap-2">
            <div>
              <BackButton navigateTo={"/"} />
            </div>
            <div>
              <h1 className="font-hore text-white pt-5 text-2xl lg:text-4xl">
                your NFT
              </h1>
              <h1 className="font-hore text-white pb-10 text-3xl lg:text-6xl -mt-3">
                Collection
              </h1>
            </div>
          </div>
          <div className="text-white font-dne_regular">{props.text}</div>
          <div className="h-screen"></div>
        </div>
      </>
    );
  };

  if (myNFTs === "LOADING") {
    return <Placeholder text={"Loading"} />;
  } else if (myNFTs === "NONE" || myNFTs.tokenUris?.length === 0) {
    return <Placeholder text={"You don't have any NFTs yet :("} />;
  } else {
    return (
      <div className="text-center bg-spiral bg-cover bg-center">
        <div className="text-center grid grid-cols-3 gap-2">
          <div>
            <BackButton navigateTo={"/"} />
          </div>
          <div>
            <h1 className="font-hore text-white pt-5 text-xl lg:text-4xl">
              your NFT
            </h1>
            <h1 className="font-hore text-white pb-10 text-2xl lg:text-6xl -mt-3">
              Collection
            </h1>
          </div>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-2 gap-4">
            {myNFTs.tokenUris?.map((uri) => {

              console.log("uri.listedPrice", uri.listedPrice)

              return (
                <div
                  className="justify-center p-2"
                  key={`nft-container-${uri.id}`}
                >
                  <Frame
                    collectable={{
                      id: uri.id,
                      seed: uri.id,
                    }}
                    fractalApiEndpoint={fractalEndpoint}
                    listPrice={uri.listedPrice}
                  />
                  {/* <a
                    className="bg-cyan-700 hover:bg-cyan-900 text-white font-bold py-2 px-4 rounded-md focus:outline-none focus:shadow-outline"
                    key={uri.id}
                    href={`${props.fractalEndpoint}/api/v1/asset/${uri.id}`}
                    download="asset"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Download
                  </a> */}
                  <Button
                    text="View image"
                    space={0.5}
                    onClick={() =>
                      window.open(
                        `${props.fractalEndpoint}/api/v1/asset/${uri.id}`
                      )
                    }
                  ></Button>
                  
                  { uri.listedPrice ? <Button
                    text="Delist NFT"
                    onClick={() => setActiveModal(uri.id)}
                  ></Button> : <Button
                    text="Sell NFT"
                    onClick={() => setActiveModal(uri.id)}
                  ></Button> }

                  <InsideMyNft
                    modalIsActive={activeModal === uri.id}
                    collectableSeed={uri.id}
                    fractalApiEndpoint={fractalEndpoint}
                    on_close={() => setActiveModal(undefined)}
                    accessToken={accessToken}
                    contractId={uri.id}
                    currentPrice={"NOT_FOR_SALE"}
                    tokenId={uri.tokenId}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div className="h-screen"></div>
      </div>
    );
  }
};
