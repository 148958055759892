import React from "react";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { BackButton } from "../components/BackButton";

import { NewArtModal } from "../components/NewArtModal";
import { ViewModal } from "../components/ViewModal";
import { Collectable } from "../models/ApiModels";
import {
  Contract,
  ContractAndBalance,
  ListedPrice,
  NftDetails,
  Token,
} from "../models/ApplicationModels";
import { InventoryServiceInterface } from "../service/InventoryService";

interface GalleryProps {
  getUsersCollectibles: (userEmail: string) => Promise<Array<Collectable>>;
  fetchTokens: (accessToken: string) => Promise<Array<Token>>;
  getWallets: (
    apiEndpoint: string,
    accessToken: string
  ) => Promise<Array<string>>;
  fractalApiEndpoint: string;
  apiEndpoint: string;
  accessToken: string;
  contracts: Contract[];
  inventoryService: InventoryServiceInterface;
  rewardTokenName: string;
}

interface CollectableFrameInfo {
  id: string;
  seed: string;
}

export const Frame = (props: {
  collectable: CollectableFrameInfo;
  fractalApiEndpoint: string;
  listPrice?: ListedPrice;
}) => {
  const [modalIsActive, setModalState] = React.useState(false);

  return (
    <div className="relative">
      {props.listPrice && (
        <div className="absolute top-0 right-0 h-16 w-16 rounded-full bg-white flex items-center justify-center">
          {props.listPrice.amount}
        </div>
      )}
      <div
        className="m-5 flex items-center place-content-center max-w-50 m-auto rounded-md aspect-square p-auto bg-accent-yellow-30 hover:bg-opacity-70 border-black border-8"
        key={props.collectable.id}
      >
        <div className="flex items-center place-content-center align-middle">
          <img
            src={`${props.fractalApiEndpoint}/api/v1/asset/${props.collectable.seed}`}
            alt={`Fractal tree for token ${props.collectable.seed}`}
            width="60%"
            onClick={() => setModalState((s) => !s)}
            // height="600"
          />
        </div>
      </div>
    </div>
  );
};

export const Gallery = (props: GalleryProps) => {
  const [activeCollectableId, setActiveCollectableId] = React.useState<
    string | undefined
  >(undefined);
  const [justBoughtCollectableId, setjustBoughtCollectableId] = React.useState<
    string | undefined
  >(undefined);
  const [bought, setBought] = useState<Boolean>(false);
  const [collectables, setCollectables] = useState<Array<Collectable>>([]);
  const [wallets, setWallets] = useState<Array<string>>([]);
  const [tokens, setTokens] = useState<Array<Token>>([]);
  const { accessToken } = props;
  const [contractAndBalance, setContractAndBalance] = useState<
    Array<ContractAndBalance>
  >([]);
  const getCollectables = async (userToken: string) => {
    const collectables = await props.getUsersCollectibles(userToken);
    setCollectables(collectables);
  };
  const [myRewardBalance, setMyRewardBalance] = useState<string | undefined>(
    undefined
  );
  const [alreadyPurchased, setAlreadyPurchased] = useState<boolean>(false);
  useEffect(() => {
    if (accessToken) {
      getCollectables(accessToken);
    }
  }, [accessToken]);

  // Getting wallets
  useEffect(() => {
    if (accessToken && wallets.length === 0) {
      props.getWallets(props.apiEndpoint, accessToken).then((wallets) => {
        setWallets(wallets);
      });
    }
  }, [accessToken]);

  // Getting tokens:
  useEffect(() => {
    if (accessToken && tokens.length === 0) {
      getCryptoAssetTokens();
    }
  }, [accessToken]);

  // Get balance
  useEffect(() => {
    if (wallets.length > 0 && accessToken && tokens.length > 0) {
      const rewardToken = tokens.filter(
        (i) => i.contractName === props.rewardTokenName
      );
      const rewardContract: Contract = {
        address: rewardToken[0].contractAddress,
        name: rewardToken[0].contractName,
        ticker: rewardToken[0].tokenTicker,
        tokenType: rewardToken[0].contractType,
      };

      props.inventoryService
        .getAssetBalance(accessToken, [rewardContract], wallets[0])
        .then(setContractAndBalance);
    }
  }, [wallets, tokens, collectables]);
  // Set balance
  useEffect(() => {
    const tokens = contractAndBalance.filter(
      (i) => i.contract.name === props.rewardTokenName
    );
    if (tokens && tokens.length > 0) {
      setMyRewardBalance("" + tokens[0].balance);
    }
  }, [contractAndBalance]);

  const getCryptoAssetTokens = async () => {
    const tokens = await props.fetchTokens(accessToken);
    setTokens(tokens);
  };

  const buyNft = async (collectableId: string) => {
    if (accessToken) {
      const requestOptions = {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}` || "anonymous",
        },
        body: JSON.stringify({
          purchaseId: uuidv4(),
          collectableId: collectableId,
        }),
      };
      const response = await fetch(
        `${props.apiEndpoint}/api/v1/collectibles/buy`,
        requestOptions
      );
      if (response.status === 500) {
        setAlreadyPurchased(true);
      } else {
        const r = await response.json();
        getCollectables(accessToken);
        setjustBoughtCollectableId(r.url);
        setBought(true);
      }
    }
  };

  const [nftContractAndBalance] = contractAndBalance;
  const ownedUris: Array<NftDetails> = nftContractAndBalance
    ? nftContractAndBalance.tokenUris || []
    : [];
  const urisFlattened = ownedUris.map((uri) => {
    return uri.url;
  });

  return (
    <>
      <div className="text-center bg-spiral bg-cover bg-center">
        <div className="text-center grid grid-cols-3 gap-2">
          <div>
            <BackButton navigateTo={"/"} />
          </div>
          <div>
            <h1 className="font-hore text-white pt-5 text-2xl lg:text-4xl">
              Nft
            </h1>
            <h1 className="font-hore text-white pb-5 text-4xl lg:text-6xl -mt-3">
              Shop
            </h1>
          </div>
        </div>
        <div className="text-white font-hore p-4 rounded bg-summer-green m-auto flex w-fit text-center">
          Your balance: {myRewardBalance} {props.rewardTokenName}
        </div>

        <div className="p-4">
          <div className="grid grid-cols-2 gap-4" id="images-collection">
            {collectables
              .filter((i) => {
                return !urisFlattened.includes(i.url);
              })
              .map((collectable) => {
                return (
                  <div key={collectable.id}>
                    <button
                      title="view"
                      type="button"
                      onClick={() => {
                        setActiveCollectableId(collectable.id);
                      }}
                    >
                      <Frame
                        collectable={collectable}
                        fractalApiEndpoint={props.fractalApiEndpoint}
                      />
                      <ViewModal
                        modalIsActive={activeCollectableId === collectable.id}
                        title={`Fractal tree`}
                        subTitle={`ID: ${collectable.seed}`}
                        text={
                          "This NFT can be yours for only 10 " +
                          props.rewardTokenName
                        }
                        img_source={`${props.fractalApiEndpoint}/api/v1/asset/${collectable.seed}`}
                        img_alt={`Fractal tree for token ${collectable.seed}`}
                        button_text={"Buy NFT"}
                        button_onClick={() => buyNft(collectable.id)}
                        onClose={() => {
                          setActiveCollectableId(undefined);
                        }}
                        collectable={collectable}
                        fractalApiEndpoint={props.fractalApiEndpoint}
                        balance={myRewardBalance}
                        isAlreadyPurchased={alreadyPurchased}
                      />
                    </button>
                    <p className="text-white font-dnb_bold">
                      Price: {collectable.price ? collectable.price : 10}{" "}
                      {props.rewardTokenName}
                    </p>
                  </div>
                );
              })}
          </div>
          {bought ? (
            <NewArtModal
              modalIsActive={true}
              collectable={justBoughtCollectableId}
              onClose={() => {
                setjustBoughtCollectableId(undefined);
              }}
            />
          ) : null}
        </div>
        <div className="p-60"></div>
      </div>
    </>
  );
};
