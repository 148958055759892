import { useEffect, useState } from "react";
import { VictoryLabel, VictoryPie } from "victory";
import { GetBalanceResponse } from "../../models/ApiModels";
import { ContractAndSupply } from "../../models/ApplicationModels";
import { useInterval } from "usehooks-ts";

export interface VaultProps {
  getReserveBalance: (
    apiEndpoint: string,
    rewardTokenName: string
  ) => Promise<GetBalanceResponse | undefined>;
  tokensInCirculation: ContractAndSupply[] | undefined;
  accessToken: string;
  rewardTokenName: string;
}

export function tokensInCirc(props: VaultProps): number {
  if (props.tokensInCirculation !== undefined) {
    const rewardToken = props.tokensInCirculation.find((tokenName) => {
      return tokenName.name === props.rewardTokenName;
    });
    if (rewardToken?.supply !== undefined) {
      return rewardToken?.supply;
    } else return 0;
  } else return 0;
}

export interface Token {
  balance: number;
  decimals: number;
}

export const Vault = (props: VaultProps) => {
  const [vaultValue, setReserveRewardToken] = useState<Token>();
  const tokenName =
    props.rewardTokenName
      .split(/(?=[A-Z])/)
      .join(" ")
      .toUpperCase() + "S";

  async function getReserveBalanceAsync() {
    const token = await props.getReserveBalance(
      props.accessToken,
      props.rewardTokenName
    );
    if (token?.balance) {
      setReserveRewardToken({
        balance: token.balance,
        // balance: token.balance / Math.pow(10, token.decimals),
        decimals: token.decimals,
      });
    } else {
      setReserveRewardToken({
        balance: 0,
        decimals: 0,
      });
    }
  }

  useInterval(() => {
    getReserveBalanceAsync();
  }, 5000);

  useEffect(() => {
    getReserveBalanceAsync();
  }, [props.accessToken]);

  const piePiece = (): number => {
    if (vaultValue) {
      const result = tokensInCirc(props) - vaultValue.balance;
      return result;
    } else {
      return 0;
    }
  };
  return (
    <div>
      <h2 className="font-hore text-2xl text-white pb-2">The Vault</h2>
      <p className="font-dne_regular text-xs text-white">{tokenName}:</p>
      <div className="pl-5">
        <svg viewBox="0 0 400 400">
          <VictoryPie
            colorScale={["#28B482", "#00343E"]}
            standalone={false}
            width={400}
            height={400}
            data={[
              { x: "", y: vaultValue?.balance },
              { x: "", y: piePiece() },
            ]}
            innerRadius={170}
            style={{
              labels: { fontSize: 1, fill: "#00343E" },
              data: {
                filter: "drop-shadow(0 0 10px 2px rgb(40 180 130 / 0.2))",
              },
            }}
          />
          <VictoryLabel
            textAnchor="middle"
            style={{ fontSize: 50, fill: "#FFFFFF" }}
            x={200}
            y={200}
            text={vaultValue?.balance}
          />
        </svg>
        <div hidden={true} />
        {piePiece()}
      </div>
    </div>
  );
};
