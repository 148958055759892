import { useAuth0 } from "@auth0/auth0-react";

export const LogoutButton = () => {
  const { logout } = useAuth0();

  return (
    <button
    type="button"
    className="bg-sea-green font-dnb_regular min-w-max text-lg text-white border-2 border-sea-green rounded-full px-8 py-0.5 hover:bg-white hover:text-ocean-green"
      onClick={() => logout({ returnTo: window.location.origin })}
    >
      LOGOUT
    </button>
  );
};
