import { DnbButton } from "../components/DnbButton";

interface HomeProps {
  navigate: (_: string) => void;
}

export const Home = (props: HomeProps) => {
  const { navigate } = props;
  return (
    <>
      <div className="bg-emerald-green ">
        <div className="justify-center flex">
          <h1 className="font-dnb_bold text-white text-center text-3xl p-10 2xl:text-6xl 2xl:p-20 xl:text-5xl xl:p-20 lg:text-4xl lg:p-10 md:text-3xl md:p-10  ">
            CRYPTLAB
          </h1>
        </div>

        <div className="grid 2xl:grid-cols-4 xl:grid-cols-3 lg:grid-cols-3 md:grid-cols-2 gap-10 mx-20">
          <div className="bg-white p-10 text-center rounded-md">
            <p className="tewx-black font-dnb_bold text-xl">
              Tic-Tac-Token hub
            </p>
            <p className="tewx-black font-dnb_regular p-5">
              Your hub for playing the Tic-Tac-Token game, buying NFTs and
              viewing your NFTs.
            </p>
            <p className="pb-5">
              <DnbButton
                text="TicTacToken hub"
                onClick={() => navigate("/tictactoken")}
              />
            </p>
          </div>

          <div className="bg-white p-10 text-center rounded-md">
            <p className="tewx-black font-dnb_bold text-xl">Buy Digital NOK</p>
            <p className="tewx-black font-dnb_regular p-5">
              Use your fiat money to buy crypto assets (tokens) like Digital
              NOK.
            </p>
            <p className="pb-5">
              <DnbButton
                text="Buy Crypto"
                onClick={() => navigate("/buycrypto")}
              />
            </p>
          </div>
          <div className="bg-white p-10 text-center  rounded-md">
            <p className="tewx-black font-dnb_bold text-xl">
              Tic-Tac-Token Game
            </p>
            <p className="tewx-black font-dnb_regular p-5">
              Takes you straight to the memory game that allows you to earn
              tokens.
            </p>
            <p className="pb-5">
              <DnbButton
                text="TicTacToken Game"
                onClick={() => navigate("/memory")}
              />
            </p>
          </div>
          <div className="bg-white p-10 text-center  rounded-md">
            <p className="tewx-black font-dnb_bold text-xl">Guessing Game</p>
            <p className="tewx-black font-dnb_regular p-5">
              Our first attempt of game to earn tokens, pure luck, no skill.
            </p>
            <p className="pb-5">
              <DnbButton text="Lotto Game" onClick={() => navigate("/lotto")} />
            </p>
          </div>
          <div className="bg-white p-10 text-center  rounded-md">
            <p className="tewx-black font-dnb_bold text-xl">Inventory</p>
            <p className="tewx-black font-dnb_regular p-5">
              Our first attempt of an inventory for managing crypto asset as the
              assest creator and owner.
            </p>
            <p className="pb-5">
              <DnbButton
                text="Invent Or Eee"
                onClick={() => navigate("/inventory")}
              />
            </p>
          </div>
          <div className="bg-white p-10 text-center  rounded-md">
            <p className="tewx-black font-dnb_bold text-xl">Your NFT Gallery</p>
            <p className="tewx-black font-dnb_regular p-5">
              An overview of the NFTs that you have bought. You can also re-sell
              them here.
            </p>
            <p className="pb-5">
              <DnbButton
                text="Your NFTs"
                onClick={() => navigate("/myTokensAndNFTs")}
              />
            </p>
          </div>
        </div>
        <div className="justify-center m-20 flex">
          <img
            src="./dnb-rgb-hvit.png"
            alt="dnb logo"
            className="2xl:h-24 xl:h-20 lg:h-18 md:h-14 h-14"
          />
        </div>
        <p className="p-20"></p>
      </div>
    </>
  );
};
