import QRCode from "react-qr-code";
import { BlockChainVisual } from "../components/BlockChainVisual";
import {
  getHighScoreNFTImpl,
  getHighScoreTokenImpl,
  HighScores,
} from "../components/HighScoreList";

import { LatestNftList } from "../components/LatestNftList";

import { Vault } from "../components/Vault";
import { ContractAndSupply } from "../models/ApplicationModels";
import { Collectable, GetBalanceResponse } from "../models/ApiModels";

interface DashBoardProps {
  tokensInCirculation: ContractAndSupply[] | undefined;
  fractalApiEndpoint: string;
  accessToken: string;
  getRecentlySoldCollectibles: () => Promise<Collectable[]>;
  rewardTokenName: string;
}

const getReserveBalance = async (
  accessToken: string,
  rewardTokenName: string
): Promise<GetBalanceResponse | undefined> => {
  const apiEndpoint = `${process.env.REACT_APP_API_ENDPOINT}`;
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}` || "anonymous",
    },
  };
  const response = await fetch(
    `${apiEndpoint}/api/v1/wallets/reserve`,
    requestOptions
  );
  const dataResponse: [GetBalanceResponse] = await response.json();
  const rewardToken = dataResponse.find((b) => b.tokenName === rewardTokenName);
  return rewardToken;
};

export const BankVaultDashBoard = (props: DashBoardProps) => {
  const { accessToken } = props;
  return (
    <div className="text-center bg-spiral bg-cover bg-center">
      <div className="grid grid-cols-3 gap-4 ">
        <div className="pl-10 pt-6">
          <div className="float-left bg-white flex w-30 aspect-square rounded">
            <div className="justify-center m-auto">
              <QRCode
                value={window.location.origin}
                size={100}
                className="m-2"
              />
              <p className="font-dnb_bold pb-2 text-ocean-green">
                Scan to play!
              </p>
            </div>
          </div>
        </div>
        <div>
          <img
            src="./TicTacTokenLogo.png"
            alt="Tic Tac Token logo"
            className="w-80 m-auto"
          />
        </div>
        <div>
          <img
            src="./dnb-logo.png"
            alt="dnb logo"
            className="float-right w-20 m-10"
          />
        </div>
      </div>

      <div className="bg-ocean-green bg-opacity-50 pt-5 pb-5 mt-5 border-t-2 border-b-2 border-summer-green mb-5">
        <div className="grid grid-cols-4 gap-10 px-10">
          <Vault
            getReserveBalance={getReserveBalance}
            tokensInCirculation={props.tokensInCirculation}
            accessToken={props.accessToken}
            rewardTokenName={props.rewardTokenName}
          />
          <div className="col-span-2">
            <LatestNftList
              title="Latest NFT"
              fractalApiEndpoint={props.fractalApiEndpoint}
              accessToken={accessToken}
              getRecentlySoldCollectibles={props.getRecentlySoldCollectibles}
            />
          </div>
          <HighScores
            getHighScoreNFT={getHighScoreTokenImpl}
            getHighScoreToken={getHighScoreNFTImpl}
          />
          {/* <RecentEvents accessToken={accessToken} /> */}
        </div>
      </div>
      <BlockChainVisual
        title={"Blockchain updates"}
        accessToken={props.accessToken}
      />
    </div>
  );
};
