import { useEffect, useState } from "react";
import { useInterval } from "usehooks-ts";
// import { useAuth0ToGetAccessToken } from "../../api/use-api";
import { RecentEvent } from "../../models/ApplicationModels";

interface BlockChainVisualProps {
  title: string;
  accessToken: string;
}

export const apiEndpoint = `${process.env.REACT_APP_API_ENDPOINT}`;

export function BlockChainVisual(props: BlockChainVisualProps) {
  const [events, setEvents] = useState<Array<RecentEvent>>([]);
  const [building, setBuilding] = useState<Boolean>(false);

  useEffect(() => {
    if (props.accessToken) {
      getTokens(props.accessToken);
    }
  }, [props.accessToken]);

  useInterval(
    () => {
      if (props.accessToken) {
        getTokens(props.accessToken);
        setBuilding(true);
      }
    },
    // Delay in milliseconds or null to stop it
    2000
  );
  const getTokens = async (authToken: string) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}` || "anonymous",
      },
    };
    const response = await fetch(
      `${apiEndpoint}/api/v1/get-recent-events`,
      requestOptions
    );
    const dataResponse: { events: Array<RecentEvent> } = await response.json();
    setEvents(dataResponse.events);
  };

  return (
    <div className="pb-20">
      <div className="font-hore text-2xl text-white pb-2">{props.title}</div>
      <div id="eventContainer" className="grid grid-cols-12">
        {events
          .slice(1, 12)
          .sort((a, b) => a.id - b.id)
          .map((event) => {
            return (
              <div key={event.id}>
                <div>
                  {" "}
                  <img
                    src="./NormalBlock.png"
                    alt="illustration of a block in the blockchain"
                    className="place-content-center m-auto xl:h-20"
                  />
                </div>
                <div className="border border-summer-green text-summer-green p-1 m-2 rounded-md  sm:h-25 md:h-20 lg:h-16 xl:h-14">
                  <p className="font-dnb_bold ">{event.id}</p>{" "}
                  <p className="font-dnb_light" style={{ fontSize: "10px" }}>{event.event}</p>
                </div>
              </div>
            );
          })}
        {building ? (
          <div>
            <img
              src="./NewBlock.png"
              alt="illustration of a block in the blockchain"
              className="animate-pulse place-content-center m-auto xl:h-20 "
            />
            <div className="border border-summer-green text-summer-green p-1 m-2 rounded-md sm:h-25 md:h-20 lg:h-16 xl:h-14 animate-pulse">
              <p key="last event id" className="font-dnb_bold ">
                {events[0].id}
              </p>{" "}
              <p key="last event event" className="font-dnb_light" style={{ fontSize: "10px" }}>
                {events[0].event}
              </p>
            </div>
          </div>
        ) : (
          <img
            src="./NewBlock.png"
            alt="illustration of a block in the blockchain"
            className=" xl:h-20 animate-pulse place-content-center m-auto "
          />
        )}
        <div>
          <img
            src="./PlaceholderBlock.png"
            alt="illustration of a block in the blockchain"
            className="xl:h-20 "
          />
        </div>
      </div>
    </div>
  );
}
