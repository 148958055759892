import AddWalletForm from "../components/AddWalletForm";
import CreateAssetForm from "../components/CreateAssetForm";
import CreateWalletForm from "../components/CreateWalletForm";
import { DnbButton } from "../components/DnbButton";
import InventoryContainer, { WalletId } from "../components/InventoryContainer";
import { Contract, ContractAndSupply, Wallet } from "../models/ApplicationModels";

export interface HomePageProps {
  wallets: Map<string, Wallet>;
  contractAddresses: Map<string, ContractAndSupply>;
  onAssetsMoved: (contract: Contract) => void;
  onWalletCreated: (wallet: Wallet) => void;
  refreshBalances: () => void;
  transfer: (
    contract: Contract,
    from: Wallet,
    to: WalletId,
    amount: number
  ) => void;
  apiEndpoint: string;
  accessToken: string;
  user: { email?: string } | undefined;
}

export const InventOrEe = (props: HomePageProps) => {
  const {
    wallets,
    contractAddresses,
    onAssetsMoved,
    onWalletCreated,
    refreshBalances,
    transfer,
    apiEndpoint,
    accessToken,
    user,
  } = props;

  return (
    <>
      <div className="grid grid-cols-4">
        <div className="bg-ocean-green p-4">
          <h2 className="font-dnb_regular text-white p-2">Create Asset</h2>
          <CreateAssetForm addAssetContract={onAssetsMoved} apiEndpoint={apiEndpoint} user={user} accessToken={accessToken} />
          <h2 className="font-dnb_regular text-white p-2">Create Wallet</h2>
          <CreateWalletForm onWalletCreated={onWalletCreated} apiEndpoint={apiEndpoint} />
          <h2 className="font-dnb_regular text-white p-2">
            Add existing wallet
          </h2>
          <AddWalletForm onWalletAdded={onWalletCreated} />
        </div>
        <div className="bg-mint-green-12 p-4">
          {
            <div className="grid grid-cols-1 gap-4">
              <div className="font-dnb_bold text-ocean-green p-0 text-2xl">
                Tokens in circulation
              </div>
              <table className="table-auto text-left">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Total Supply</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.from(contractAddresses).map(([_, value], index) => {
                  return (
                    <tr key={`tokens-${index}`}>
                      <td>{value.name}</td>
                      <td>{value.supply}</td>
                    </tr>
                  );
                })}
                </tbody>
              </table>
              
            </div>
          }
        </div>
        <div className="col-span-2 p-4">
          <div className="float-right">
            <DnbButton text="Refresh" onClick={refreshBalances} />
          </div>
          <div className="pt-6">
            {Array.from(wallets.values()).map((wallet) => {
              return (
                <InventoryContainer
                  onTransfer={transfer}
                  key={`inventory-container=${wallet.address}`}
                  wallet={wallet}
                  otherWallets={Array.from(wallets.values()).filter(
                    (i) => i.address !== wallet.address
                  )}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
