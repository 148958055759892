import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

export const LoginPage = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <>
      <div className="text-center bg-spiral bg-cover bg-center">
        <div className="flex flex-center flex-col">
          <div></div>
          <div className="m-auto">
            <img
              src="./dnb-logo.png"
              alt="dnb logo"
              className="float-right w-14 m-5 "
            />
          </div>
          <div>
            <img
              src="./TicTacTokenLogo.png"
              alt="Tic Tac Token logo"
              className="w-80 m-auto"
            />
          </div>
          <p className="font-dnb_regular text-white pb-10 pt-5">
            {" "}
            Welcome to the DNB Tic Tac Token Game.
          </p>
          <p className="font-dnb_regular text-white pb-10">
            {" "}
            Sign in to get started!
          </p>
          <p className="pb-5">
            <button
              className="bg-sea-green font-dnb_regular text-lg text-white border-2 border-sea-green rounded-full px-8 py-0.5 hover:bg-white hover:text-ocean-green"
              onClick={() => loginWithRedirect()}
            >
              Sign in
            </button>
          </p>
          <p className="font-dnb_regular text-white w-9/12 flex m-auto">
            (We use a third party auth provider to make it easy and fast for you
            to sign up. You will be redirected to an Auth0 sign in page, where
            you can login with your gmail account, or create a new user by
            providing an email and a password.)
          </p>
        </div>
        <div className="h-screen"></div>
      </div>
    </>
  );
};
