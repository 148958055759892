import { useNavigate } from "react-router-dom";

interface BackButtonProps {
  navigateTo: string;
}
export function BackButton(props: BackButtonProps) {
  const navigate = useNavigate();
  return (
    <button type="button" className="float-left p-4">
      <img
        src={"./arrow.png"}
        alt={`back arrow`}
        onClick={() => navigate(props.navigateTo)}
      />
    </button>
  );
}
