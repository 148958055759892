import _ from "lodash";
import { useEffect, useState } from "react";
import { Fireworks } from "@fireworks-js/react";
import { useTimer } from "react-timer-hook";
import { Spinner } from "../components/Spinner";
import { InventoryServiceInterface } from "../service/InventoryService";
import { Prize, RewardResponse } from "../models/ApiModels";
import { Contract, ContractAndBalance } from "../models/ApplicationModels";
import { DnbButton } from "../components/DnbButton";
import { useNavigate } from "react-router-dom";
import { getWallets } from "../service/UserService";

export interface RedeemPageProps {
  contracts: Contract[];
  inventoryService: InventoryServiceInterface;
  apiEndpoint: string;
  fractalEndpoint: string;
  accessToken: string;
  rewardTokenName: string;
  nftName: string;
}

const fiveSeconds = (): Date => {
  const time = new Date();
  time.setSeconds(time.getSeconds() + 5);
  return time;
};

export const RedeemPage = (props: RedeemPageProps) => {
  const { accessToken } = props;
  const navigate = useNavigate();
  const [fireworksEnabled, setFireworksEnabled] = useState<boolean>(false);
  const { start: startFireworksTimer } = useTimer({
    expiryTimestamp: fiveSeconds(),
    onExpire: () => setFireworksEnabled(false),
  });
  const [reward, setReward] = useState<number | undefined>(undefined);
  const [jackpots, setJackpots] = useState<Array<Prize>>([]);
  const { contracts, inventoryService } = props;
  const [selectedCard, setSelectedCard] = useState<number | undefined>(
    undefined
  );
  const [wallets, setWallets] = useState<Array<string>>([]);
  const [contractAndBalance, setContractAndBalance] = useState<
    Array<ContractAndBalance>
  >([]);
  const [myRewards, setMyRewards] = useState<Array<ContractAndBalance>>([]);
  const [myTokens, setTokens] = useState<Array<ContractAndBalance>>([]);

  const getBalances = async (userToken: string, wallet: string) => {
    const balances = await inventoryService.getAssetBalance(
      userToken,
      contracts,
      wallet
    );
    setContractAndBalance(balances);
  };

  useEffect(() => {
    const rewards = contractAndBalance.filter(
      (i) => i.contract.name === props.nftName
    );
    setMyRewards(rewards);
  }, [contractAndBalance]);

  useEffect(() => {
    const tokens = contractAndBalance.filter(
      (i) => i.contract.name === props.rewardTokenName
    );
    setTokens(tokens);
  }, [contractAndBalance]);

  useEffect(() => {
    if (accessToken && wallets.length === 0) {
      getWallets(props.apiEndpoint, accessToken).then((wallets) => {
        setWallets(wallets);
      });
    }
  }, [accessToken]);

  useEffect(() => {
    if (wallets.length > 0 && accessToken) {
      getBalances(accessToken, wallets[0]);
    }
  }, [wallets]);

  const chooseCard = async (n: number) => {
    if (!selectedCard && accessToken) {
      setSelectedCard(n);

      const data = {
        choice: n,
      };

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}` || "anonymous",
        },
        body: JSON.stringify(data),
      };
      const response = await fetch(
        `${props.apiEndpoint}/api/v1/daily-reward`,
        requestOptions
      );
      const resp: RewardResponse = await response.json();

      const JACKPOT_AMOUNT = 5;
      if (resp.prizeAwarded.value === JACKPOT_AMOUNT) {
        setFireworksEnabled(true);
        startFireworksTimer();
      }

      setReward(resp.prizeAwarded.value);
      setJackpots(resp.prizes);
      getBalances(accessToken, wallets[0]);
    }
  };

  // const consumeToken = async () => {
  //   if (accessToken) {
  //     const requestOptions = {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${accessToken}` || "anonymous",
  //       },
  //       body: JSON.stringify({}),
  //     };
  //     const response = await fetch(
  //       `${props.apiEndpoint}/api/v1/daily-reward/use-reward`,
  //       requestOptions
  //     );
  //     await response.json();
  //     // setAssets([...assets, r.reward.id]);
  //     // getAsset(r.reward.id)
  //     getBalances(accessToken, wallets[0]);
  //   }
  // };

  const NFT_COST = 10;

  return (
    <div>
      <div className="text-center p-8 justify-center">
        <h1 className="text-4xl text-ocean-green font-dnb_bold">
          The token game
        </h1>
        <p className="p-3 font-dnb_light">
          {" "}
          Pick a card and get your reward in tokens. The jackpot is 5 tokens.
          There is also one card holding 3 tokens and one holding 2 tokens. All
          the other cards hold 1 token. You can only play once every day, and
          when you hit 10 tokens you can buy your very own NFT and get a Fractal
          Tree representing that specific NFT.
        </p>
      </div>
      <div className="grid grid-cols-4 border-8 border-accent-yellow">
        <div className="text-center bg-accent-yellow-30 col-span-1">
          <div className="p-4">
            {myTokens.map((c, i) => {
              const hasBalance = c.balance >= NFT_COST;
              return (
                <div key={`contract-name-${c.contract.address}`}>
                  <div className="bg-mint-green-12 rounded-full m-5 p-3 border-2 border-ocean-green">
                    <h2 className="font-dnb_bold p-2">
                      Your current reward tokens:
                    </h2>{" "}
                    <span className={`text-4xl font-dnb_bold`}>
                      {c.balance}
                    </span>{" "}
                    {/* {c.contract.name}'s{" "} */}
                  </div>
                  <p className="pb-5">
                    <DnbButton
                      text="Buy NFTS"
                      onClick={() => navigate("/gallery")}
                    />
                  </p>
                  <div className="p-2 font-dnb_regular">
                    {!hasBalance
                      ? "You need 10 tokens before you can buy NFT"
                      : ""}
                  </div>
                </div>
              );
            })}
          </div>

          {/* {assets.map((a) => {
          return (
            <a
              key={a}
              href={`${apiEndpoint}/api/v1/asset/${a}`}
              download="asset"
            >
              Download
            </a>
          );
        })} */}
        </div>

        <div className="col-span-3 bg-accent-yellow-30 p-6 ">
          <div className="grid grid-cols-5 m-2 gap-3 ">
            {_.range(25).map((i) => {
              const jackpot = jackpots.find((p) => p.location === i);
              return (
                <div key={i} className="relative ">
                  <div
                    className={`drop-shadow-lg relative rounded-md bg-ocean-green h-[100px] p-8 cursor-pointer duration-1000 ${
                      (i === selectedCard || jackpot) && "scale-0"
                    } `}
                    onClick={() => {
                      return chooseCard(i);
                    }}
                  ></div>
                  <div
                    className={`drop-shadow-lg absolute text-center font-bold text-2xl rounded-md top-0 h-[100px] w-full bg-sea-green p-8 cursor-pointer duration-1000 ${
                      i === selectedCard || jackpot ? "scale-100" : "scale-0"
                    } `}
                    onClick={() => {
                      return chooseCard(i);
                    }}
                  >
                    {jackpot ? (
                      `👑 ${jackpot.value} 👑`
                    ) : reward ? (
                      reward
                    ) : (
                      <Spinner />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {fireworksEnabled && (
          <Fireworks
            options={{ opacity: 0.5 }}
            style={{
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              position: "fixed",
              background: "transparent",
            }}
          />
        )}
      </div>

      <div className="text-center p-10 ">
        <h1 className="text-3xl font-bold ">Your NFT's</h1>
        <div className="col-span-3 ">
          <div className="grid grid-cols-3 m-2 gap-3 ">
            {myRewards.map((reward) => {
              return reward.tokenUris?.map((uri) => {
                return (
                  <div
                    className="justify-center border-2 p-4 rounded-md"
                    key={`nft-container-${uri}`}
                  >
                    <img
                      src={`${props.fractalEndpoint}/api/v1/asset/${uri.id}`}
                      alt={`Fractal tree for token ${uri.id}`}
                      width="500"
                      height="600"
                    />
                    <a
                      className="bg-cyan-700 hover:bg-cyan-900 text-white font-bold py-2 px-4 rounded-md focus:outline-none focus:shadow-outline"
                      key={uri.id}
                      href={`${props.fractalEndpoint}/api/v1/asset/${uri.id}`}
                      download="asset"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Download
                    </a>
                  </div>
                );
              });
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
