import { User } from "@auth0/auth0-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { LogoutButton } from "../LogoutButton";

export function NavBar(user: User | undefined) {
  const navigate = useNavigate();
  const [userMenuOpen, setUserMenuOpen] = useState<boolean>(false);
  return (
    <nav className="flex sm:justify-left space-x-4 bg-sea-green text-white p-3">
      {[
        ["Home", "/"],
        ["Invent or Eee", "/inventory"],
        ["Lotto Game", "/lotto"],
        ["Memory Game", "/memory"],
        ["Frog", "/reports"],
        ["My Tokens and NFTs", "/myTokensAndNFTs"],
        ["Dashboard", "/dashboard"],
        ["Gallery", "/gallery"],
      ].map(([title, url]) => (
        <a
          key={url}
          href={url}
          onClick={(e) => {
            e.preventDefault();
            navigate(url);
          }}
          className="rounded-lg px-3 py-2 text-slate-700 font-medium hover:bg-slate-100 hover:text-slate-900"
        >
          {title}
        </a>
      ))}
      <div className="absolute right-3 top-3">
        <button
          onClick={() => setUserMenuOpen(!userMenuOpen)}
          className="w-10 h-10 inline-block p-3 bg-signal-orange text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-summer-green hover:shadow-lg focus:bg-summer-green focus:shadow-lg focus:outline-none focus:ring-0 active:bg-ocean-green active:shadow-lg transition duration-150 ease-in-out mx-1 float-right"
        >
          {user?.name && user?.name[0].toUpperCase()}
        </button>
        <div
          className={`${
            !userMenuOpen && "hidden"
          } z-50 my-4 text-base list-none bg-sand-yellow rounded divide-y divide-gray-100 shadow dark:bg-black-80 dark:divide-gray-600`}
          id="user-dropdown"
        >
          <div className="py-3 px-4">
            <span className="block text-sm text-white dark:text-white">
              {user?.name}
            </span>
            {/* <span className="block text-sm font-medium text-gray-500 truncate dark:text-gray-400">name@flowbite.com</span> */}
          </div>
          <ul className="py-1" aria-labelledby="user-menu-button">
            <li>
              <a
                href="#"
                className="block py-2 px-4 text-sm text-white hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-white dark:hover:text-white"
              >
                Dashboard
              </a>
            </li>
            <li>
              <a
                href="#"
                className="block py-2 px-4 text-sm text-white hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-white dark:hover:text-white"
              >
                Settings
              </a>
            </li>
            <li>
              <a
                href="#"
                className="block py-2 px-4 text-sm text-white hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-white dark:hover:text-white"
              >
                Earnings
              </a>
            </li>
            <li>
              <LogoutButton />
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
