import { apiEndpoint } from "..";
import { Collectable } from "../models/ApiModels";

export const getCollectibles = async (userToken: string): Promise<Array<Collectable>> => {
    const requestOptions = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userToken}` || "anonymous",
      },
    };
    const response = await fetch(
      `${apiEndpoint}/api/v1/collectibles/for-sale`,
      requestOptions
    );
    const dataResponse: Array<Collectable> = await response.json();
    return dataResponse;
};

export const getRecentlySoldCollectibles = async (): Promise<Array<Collectable>> => {
  const requestOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  const response = await fetch(
    `${apiEndpoint}/api/v1/collectibles/recently-purchased`,
    requestOptions
  );
  const dataResponse: Array<Collectable> = await response.json();
  return dataResponse;
};

