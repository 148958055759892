import { Button, Dialog } from "@dnb/eufemia";
import { useNavigate } from "react-router-dom";

export const NewArtModal = ({
  modalIsActive,
  collectable,
  onClose,
}: {
  modalIsActive: boolean;
  collectable: string | undefined;
  onClose: () => void;
}) => {
  const navigate = useNavigate();
  const goToMyNFTs = () => navigate("/myTokensAndNFTs");
  const whenClosing = () => {
    onClose();
    window.location.reload();
  };
  return (
    <>
      <Dialog
        variant="confirmation"
        omitTriggerButton={true}
        confirmText="Shop more"
        hideDecline
        openState={modalIsActive}
        onConfirm={whenClosing}
        labelledBy="custom-triggerer"
      >
        <h1 className="font-hore text-4xl flex justify-center pb-5">
          Congratulations on your new NFT!
        </h1>
        <div className="bg-mint-green-25 rounded-md p-4 m-3">
          <p className="font-dnb_bold text-center">How does it work? </p>
          <p className="font-dnb_regular text-center p-2">
            {" "}
            NFT stands for "Non fungible token", which means that it is unique -
            one of a kind.
          </p>{" "}
          <p className="font-dnb_regular text-center p-2">
            {" "}
            The mechanics are simpler than you might think. Your NFT just
            contains a link:
          </p>
          <p className="font-dnb_bold py-1 pb-1">"{collectable}" </p>
          <p className="font-dnb_regular text-center p-2">
            This link is stored in your wallet on our blockchain. The link
            points to a distributed file store, called IPFS, which contains data
            about your NFT and links to the image you just purchased.
          </p>
        </div>
        <div className="flex justify-center">
          <Button
            text="See in my gallery"
            top="1.5rem"
            size="large"
            onClick={() => {
              goToMyNFTs();
            }}
          />
        </div>
      </Dialog>
    </>
  );
};
